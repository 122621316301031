.flex {
  display: flex;
}

.list {
  padding: var(--space-4x) 0 0;
  list-style: square inside;
}

.list > li {
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
  color: var(--color-green-40);
}

.list > li + li {
  margin-top: var(--space);
}

.list > li > span {
  color: var(--color-black);
}

.imageWrapper {
  position: relative;
  margin: 0 0 0 var(--space-6x);
  width: 460px;
  height: 230px;
  border-radius: var(--rounded);
  overflow: hidden;
}

.buttonWrapper {
  width: 32%;
  margin: 0 auto;
}
.buttonContent {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  .imageWrapper {
    height: 180px;
  }

  .buttonWrapper {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .flex {
    flex-direction: column-reverse;
  }
  .imageWrapper {
    margin: 0 0 var(--space-6x) 0;
    width: 100%;
    height: auto;
    aspect-ratio: 460 / 230;
    max-width: unset;
    max-height: unset;
  }
}
