.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-white);
  overflow: auto;
  overscroll-behavior-y: none;
  z-index: var(--z-index-modal);
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 26px var(--space-10x);
  margin-bottom: var(--space-4x);
  background: var(--color-white);
  display: flex;
  align-items: center;
  z-index: calc(var(--z-index-modal) + 1);
  border: 1px solid var(--color-gray-20);
}

.close {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.close i {
  margin-right: var(--space);
}

@media screen and (max-width: 1024px) {
  .header {
    height: 50px;
    padding: var(--space-3x) var(--space-2x);
    border: 0px;
  }
  .body {
    padding: 0;
  }
}
