.laptopWithUpVisible {
  display: none;
}

.tabletWithDownVisible {
  display: block;
}

.spaceTop {
  margin-top: 20px;
}

@media screen and (min-width: 1025px) {
  .tabletWithDownVisible {
    display: none;
  }

  .laptopWithUpVisible {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .carousel {
    margin-inline: calc(var(--space-4x) * -1);
  }
  .carousel li:first-child {
    padding-left: var(--space-4x);
  }
  .carousel li:last-child {
    padding-right: var(--space-4x);
  }
}
