.root {
  position: relative;
  min-height: 120px;
  height: 25vw;
  max-height: 200px;
  width: 100%;
  background: linear-gradient(
    134deg,
    rgba(28, 150, 157, 1),
    rgba(197, 197, 197, 1) 63%
  );
  z-index: var(--z-index-header);
}

.flexContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--space-5x);
  padding-right: var(--space-5x);
}

.bgImg {
  position: absolute;
  top: 0;
  left: 0;
}

.leftContent {
  width: 65vw;
  min-width: 195px;
  max-width: 500px;
}

.leftContent .text {
  position: relative;
  margin-bottom: var(--space-2x);
  min-height: 52px;
  height: 12vw;
  max-height: 96px;
  aspect-ratio: 191 / 52;
}

.rightContent {
  position: relative;
  min-height: 120px;
  height: 25vw;
  max-height: 200px;
  aspect-ratio: 106 / 120;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.action a {
  border-color: transparent;
}

@media screen and (max-width: 767px) {
  .flexContent {
    justify-content: space-between;
    padding-right: 30px;
  }
}
