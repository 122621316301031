.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex > :nth-child(2) {
  flex-shrink: 0;
  margin-left: var(--space-2x);
}
