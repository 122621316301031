.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 128px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  width: 32px;
  height: 32px;
  border: 2px solid var(--color-gray-80);
  border-radius: 50%;
  background: none;
}
.button:not(:disabled):hover {
  opacity: 0.8;
  cursor: pointer;
}
.button:disabled {
  border-color: var(--color-gray-30);
}
.button:disabled path {
  fill: var(--color-gray-30);
}
.input {
  appearance: none;
  border: 0;
  outline: none;
  text-align: center;
  width: 64px;
  padding-left: var(--space);
  padding-right: var(--space);
  font-size: var(--fontSize-xl);
}