.root {
  position: relative;
}
.carousel {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.items {
  display: flex;
  position: relative;
  width: 100%;
  transition: left 500ms ease;
}
.item:not(:first-child) {
  margin-left: var(--space-6x);
}

.items-centered {
  justify-content: center;
}

.leftButton,
.rightButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border-gray-dark);
  background: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.leftButton:hover,
.rightButton:hover {
  background: var(--color-gray-5);
}
.leftButton {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(var(--space-2x), -50%);
}
.rightButton {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(calc(-1 * var(--space-2x)), -50%);
}

@media screen and (max-width: 725px) {
  .items-centered {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .carousel {
    padding: 0;
    overflow: auto;
  }
  .items {
    transition: none;
  }

  .item + .item {
    margin-left: var(--space-4x);
  }
  .leftButton,
  .rightButton {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .leftButton {
    transform: translate(calc(-100% - var(--space-4x)), -50%);
  }
  .rightButton {
    transform: translate(calc(100% + var(--space-4x)), -50%);
  }
}
