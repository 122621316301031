.tooltip {
  cursor: default;
  position: absolute;
  padding: var(--space-2x) var(--space-2x) var(--space-2x) var(--space-4x);
  display: flex;
  white-space: nowrap;
  line-height: var(--lineHeight-base);
  border-radius: var(--rounded);
  font-size: var(--fontSize-sm);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  left: 8px;
  will-change: filter;
  z-index: var(--z-index-float);
}

.tooltip::before {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  margin-left: -8px;
}

.position-top,
.position-topLeft,
.position-topRight {
  transform: translateX(-50%);
  bottom: 140%;
}

.position-top::before,
.position-topLeft::before,
.position-topRight::before {
  border-top: 8px solid var(--color-white);
  bottom: -16px;
}

.position-bottom,
.position-bottomLeft,
.position-bottomRight {
  margin-top: 32px;
}

.position-bottom::before,
.position-bottomLeft::before,
.position-bottomRight::before {
  border-bottom: 8px solid var(--color-white);
  top: -16px;
}

.color-white {
  background: var(--color-white);
  color: var(--color-gray-60);
}

.color-green {
  background: var(--color-green-5);
  color: var(--color-black);
}

.position-top.color-green::before,
.position-topLeft.color-green::before,
.position-topRight.color-green::before {
  border-top: 8px solid var(--color-green-5);
}

.position-bottom.color-green::before,
.position-bottomLeft.color-green::before,
.position-bottomRight.color-green::before {
  border-bottom: 8px solid var(--color-green-5);
}

.position-top:before {
  left: 50%;
}

.position-topLeft {
  transform: translateX(-90%);
}

.position-topLeft:before {
  left: 90%;
}

.position-topRight {
  transform: translateX(-10%);
}

.position-topRight:before {
  left: 10%;
}

.position-bottom {
  transform: translateX(-50%);
}

.position-bottom:before {
  left: 50%;
}

.position-bottomLeft {
  transform: translateX(-90%);
}

.position-bottomLeft:before {
  left: 90%;
}

.position-bottomRight {
  transform: translateX(-10%);
}

.position-bottomRight:before {
  left: 10%;
}

.inner {
  cursor: text;
  margin-right: var(--space-2x);
}

.close {
  cursor: pointer;
}
