/* Figma https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=3505%3A7580 */

.root {
  font-size: var(--fontSize-sm);
  font-weight: bold;
  padding: 0 var(--space-2x);
  line-height: var(--lineHeight-base);
  border-radius: var(--rounded-lg);
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
}

/* Primary */
.primary--solid {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.primary--outline {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.primary--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-primary);
}

/*  Default */
.default--solid {
  background-color: var(--color-default);
  border-color: var(--color-default);
  color: var(--color-white);
}

.default--outline {
  background-color: var(--color-white);
  border-color: var(--color-default);
  color: var(--color-default);
}

.default--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-default);
}

/*  Danger */
.danger--solid {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
}

.danger--outline {
  background-color: var(--color-white);
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.danger--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-danger);
}

/* Disabled */
.disabled--solid {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
  color: var(--color-disabled-dark);
}

.disabled--outline {
  background-color: var(--color-white);
  border-color: var(--color-disabled-dark);
  color: var(--color-disabled-dark);
}

.disabled--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-disabled-dark);
}
