.common {
  position: relative;
  display: block;
  padding-left: 20px;
}

.summary {
  position: relative;
  display: block;
  margin-bottom: var(--space-3x);
  font-weight: bold;
  width: 100%;
  line-height: var(--lineHeight-lg);
}

.hide {
  display: none;
}

.allowToggle {
  cursor: pointer;
}

.content {
  position: relative;
  display: block;
  font-size: var(--fontSize-sm);
  line-height: 22px;
}

.summary::-webkit-details-marker {
  display: none;
}

.summaryInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

.summaryInnerSpaceBetween {
  justify-content: space-between;
}

.summaryInnerCenter {
  justify-content: center;
}

.icon {
  margin-top: -2px;
}

.closeSummary {
  display: flex;
  justify-content: center;
  margin-top: var(--space-4x);
  margin-bottom: var(--space-4x);
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  font-weight: bold;
  color: var(--color-black);
}
