.root {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--space-4x);
}

.loading {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.options {
  margin-top: var(--space-8x);
  width: 100%;
  flex: 1;
  overflow-y: auto;
}
