.root {
  display: flex;
}

.root.alignCenter {
  align-items: center;
}

.imageWrapper {
  margin-right: var(--space-6x);
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.description {
  margin-top: var(--space-2x);
}

@media (max-width: 767px) {
  .imageWrapper {
    margin-right: var(--space-4x);
  }

  .image {
    width: 48px;
    height: 48px;
  }
}
