.root {
  background-color: var(--color-primary);
  padding: var(--space) var(--space-4x);
  font-size: var(--fontSize-sm);
}

@media screen and (min-width: 1025px) {
  .root {
    display: none;
  }
}

.button {
  padding: 10px 12.5px;
}
