.root {
}

.displayPriceDescription {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
  font-weight: bold;
  color: var(--color-gray-50);
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .displayPriceDescription {
    text-align: right;
  }
}
div + .spacer {
  display: block;
  width: var(--space-1);
  height: var(--space-2x);
}
