.root {
  padding: var(--space-3x);
  border-radius: var(--rounded);
  border-width: 1px;
  border-style: solid;
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
}

.root p {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

.root.bold p {
  font-weight: bold;
}

.centered {
  text-align: center;
}

.primary--solid {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.primary--outline {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.disabled--solid {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
  color: var(--color-disabled);
}

.disabled--outline {
  background-color: var(--color-white);
  border-color: var(--color-disabled);
  color: var(--color-disabled);
}
