.destinationDetailsItems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-4x);
}

@media screen and (max-width: 768px) {
  .destinationDetailsItems {
    grid-template-columns: 1fr;
    gap: var(--space-2x);
  }
}
