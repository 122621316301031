.root {
  border-bottom: 1px solid var(--color-gray-10);
  margin-bottom: var(--space-6x);
}

.list {
  height: 40px;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}
.list::-webkit-scrollbar {
  display: none;
}

.listItem {
  text-align: center;
  padding: 6px var(--space-4x) 10px;
  color: var(--color-gray-50);
  cursor: pointer;
}

.listItem > span {
  white-space: nowrap;
}

.listItem.active {
  position: relative;
  color: var(--color-gray-80);
}

.listItem.active:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 6px; /*線の上下位置*/
  display: inline-block;
  width: 56px; /*線の長さ*/
  height: 4px; /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: var(--color-green-40); /*線の色*/
  border-radius: 2px; /*線の丸み*/
}
