.root {
  background-image: linear-gradient(
    270deg,
    var(--color-white),
    var(--color-gray-10),
    var(--color-gray-10),
    var(--color-white)
  );
  min-height: 24px;
  border-radius: var(--rounded);
  background-size: 400% 100%;
  animation: skeleton_loading 8s ease-in-out infinite;
}

.rounded {
  border-radius: 50%;
}

@keyframes skeleton_loading {
  0% {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
