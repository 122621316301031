.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.inner {
  flex-grow: 1;
  margin-top: var(--space);
}
