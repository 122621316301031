.root {
  padding: var(--space-6x) var(--space-5x) 0;
}

.root:after {
  content: "";
  display: block;
  left: var(--space-5x);
  right: var(--space-5x);
  margin: auto;
  margin-top: var(--space-4x);
  height: 1px;
  background-color: var(--border-gray);
  overflow: hidden;
}

.root > span {
  display: flex;
  align-items: center;
}

.history {
  display: flex;
  padding-top: var(--space-4x);
}

.history:hover {
  opacity: 0.7;
}

.history .iconWrapper {
  width: 16px;
}

.history i {
  margin-top: var(--space);
}

.details {
  width: 100%;
  padding-left: var(--space-4x);
  padding-right: var(--space-3x);
}

.details span {
  line-height: 24px;
}
