.footer {
  padding: 16px 24px;
}

.action {
  padding: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background-color: #00cd68;
  border-radius: 100px;
}

.close {
  text-align: center;
}

.close button {
  padding: 10px 40px;
  font-weight: bold;
  color: #757575;
  background: none;
  border: none;
}

.image {
  width: 100%;
  object-fit: cover;
}

.description {
  padding: 16px 24px;
}
