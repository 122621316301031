.wrapper {
  position: relative;
}
.wrapper.scrollable {
  overflow: auto;
}
.months {
  display: flex;
  gap: var(--space-16x);
}

.monthsScrollable {
  flex-direction: column;
  gap: 0;
}

.month {
  position: relative;
}
.monthGrid {
  margin-left: auto;
  margin-right: auto;
}
.caption {
  position: relative;
}
.captionLabel {
  display: block;
  padding-bottom: var(--space);
  font-size: var(--fontSize-2xl);
  font-weight: normal;
  text-align: center;
}
.nav {
  position: absolute;
  width: 100%;
}
.navBtnPrev,
.navBtnNext {
  position: absolute;
  appearance: none;
  border: 0;
  background: none;
  z-index: 1;
}
.navBtnPrev:hover,
.navBtnNext:hover {
  opacity: 0.8;
  cursor: pointer;
}
.navBtnPrev {
  top: 0;
  left: 8px;
}
.navBtnNext {
  top: 0;
  right: 8px;
}
.day:first-child:not(.dayDisabled):not(.dayOutside) {
  color: var(--color-red-80);
}
.day:last-child:not(.dayDisabled):not(.dayOutside) {
  color: var(--color-blue-80);
}
.day.holiday:not(.dayDisabled):not(.dayOutside) {
  color: var(--color-red-80);
}
.headCell {
  padding-top: var(--space-4x);
  padding-bottom: var(--space-4x);
  font-size: var(--fontSize-base);
  color: var(--color-gray-50);
  font-weight: bold;
  text-align: center;
  width: 44px;
}
.headCell:first-child {
  color: var(--color-red-80);
}
.headCell:last-child {
  color: var(--color-blue-80);
}

.day {
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
}
.dayInner {
  --day-custom-color: rgba(255, 255, 255, 0);
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 14px;
  transition: all var(--duration-fast);
  color: inherit;
  cursor: pointer;
}
.dayText {
  font-weight: bold;
  font-size: var(--fontSize-base);
  white-space: nowrap;
}
.dayPrice {
  font-size: var(--fontSize-xs);
  color: var(--color-gray-80);
  margin-top: 2px;
}

/*
Overwrite day styles in this order

Selectable（選択可能な日付）
↓
CustomColor（カスタム色を持つ日付）
↓
RecommendedRangeTo（Fromのみ選択中にToの選択肢としてレコメンドしている日付）
↓
SelectedRange（選択しているFrom〜Toの日付）
↓
SelectedRangeFrom & selectedRangeTo（選択しているFromの日付 & 選択しているToの日付）
*/

/* day modifiers base styles */
.day {
  position: relative;
  color: var(--color-default);
  cursor: pointer;
}
.dayHidden {
  visibility: hidden;
}
.dayNone {
  display: none;
}
.day.dayDisabled,
.day.dayOutside,
.day.dayDisabled .dayInner,
.day.dayOutside .dayInner {
  color: var(--color-disabled);
  cursor: not-allowed;
}
.dayPast:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 1px;
  background-color: var(--color-disabled-dark);
  z-index: 3;
}
.dayCustomColor:not(.dayDisabled):not(.dayOutside):not(
    .daySelectedRangeFrom
  ):not(.daySelectedRange)
  .dayInner {
  background-color: var(--day-custom-color);
}
.dayRecommendedRangeTo:not(.dayDisabled):not(.dayOutside):not(
    .daySelectedRangeFrom
  ):not(.daySelectedRange)
  .dayInner {
  background-color: var(--color-primary-light);
}
.daySelectedRange:not(.dayPast):before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -5px;
  right: -5px;
  height: 44px;
  transform: translateY(-50%);
  background: var(--color-primary-light);
  z-index: 1;
}
.daySelectedRangeFrom:not(.dayPast):not(.dayDisabled) .dayInner,
.daySelectedRangeTo:not(.dayPast):not(.dayDisabled) .dayInner {
  color: var(--color-white);
  background: none;
  border-color: var(--color-transparent);
}
.daySelectedRangeFrom .dayPrice,
.daySelectedRangeTo .dayPrice {
  color: var(--color-white);
}
.daySelectedRangeFrom:not(.dayPast):not(.dayDisabled):before {
  left: auto;
  right: -5px;
  width: 50%;
}
.daySelectedRangeTo:not(.dayPast):not(.dayDisabled):before {
  left: -5px;
  right: auto;
  width: 50%;
}
.daySelectedRangeFrom:not(.dayPast):not(.dayDisabled):after,
.daySelectedRangeTo:not(.dayPast):not(.dayDisabled):after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 46px;
  height: 44px;
  transform: translateY(-50%);
  z-index: 1;
}
.daySelectedRangeFrom:not(.dayPast):not(.dayDisabled):after {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='44' viewBox='0 0 46 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 14C0 6.26801 6.26802 0 14 0H29.8185C34.927 0 39.4754 3.23409 41.153 8.05919L45.5433 20.6864C45.8391 21.5372 45.8391 22.4628 45.5433 23.3136L41.153 35.9408C39.4754 40.7659 34.927 44 29.8186 44H14C6.26802 44 0 37.732 0 30V14Z' fill='%2300CD68'/%3E%3C/svg%3E%0A");
  z-index: 2;
}
.daySelectedRangeTo:not(.dayPast):not(.dayDisabled):after {
  right: 1px;
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='44' viewBox='0 0 46 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M46 14C46 6.26801 39.732 0 32 0H16.1815C11.073 0 6.5246 3.23409 4.84699 8.05919L0.456718 20.6864C0.160923 21.5372 0.160923 22.4628 0.456718 23.3136L4.84699 35.9408C6.5246 40.7659 11.073 44 16.1814 44H32C39.732 44 46 37.732 46 30V14Z' fill='%2300CD68'/%3E%3C/svg%3E%0A");
  z-index: 2;
}

/* day modifiers hover styles */
.day:not(.dayDisabled):not(.dayOutside):not(.daySelectedRangeFrom):not(
    .daySelectedRange
  ):hover
  .dayInner {
  background-color: var(--color-primary-light);
}
.dayCustomColor:not(.dayDisabled):not(.dayOutside):not(
    .daySelectedRangeFrom
  ):not(.daySelectedRange):hover
  .dayInner {
  background-color: var(--day-custom-color-hover);
}
.dayRecommendedRangeTo:not(.dayDisabled):not(.dayOutside):not(
    .daySelectedRangeFrom
  ):not(.daySelectedRange):hover
  .dayInner {
  background-color: var(--color-primary-light);
  color: var(--color-black);
  border-color: var(--color-transparent);
  opacity: 0.8;
}
.daySelectedRange:not(.dayPast):not(.dayDisabled):hover .dayInner {
  background: none;
  border-color: var(--color-transparent);
  color: var(--color-black);
  opacity: 0.8;
}
.daySelectedRangeFrom:not(.dayPast):not(.dayDisabled):hover .dayInner,
.daySelectedRangeTo:not(.dayPast):not(.dayDisabled):hover .dayInner {
  background: none;
  border-color: var(--color-transparent);
  color: var(--color-white);
}

/* tablet styles */
@media screen and (max-width: 1024px) {
  .wrapper {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-top: var(--space-6x);
    padding-bottom: var(--space-10x);
  }
  .navBtn {
    display: none;
  }
  .caption {
    padding-left: var(--space-6x);
    padding-right: var(--space-6x);
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
  .captionLabel {
    padding-bottom: var(--space-2x);
    font-size: var(--fontSize-2xl);
    font-weight: normal;
    text-align: left;
  }
  .month + .month {
    padding-top: var(--space-6x);
  }
  .table {
    margin-left: auto;
    margin-right: auto;
  }
  .cell {
    width: 46px;
  }
}
