.root {
  display: flex;
  align-items: center;
}

.centered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  margin-left: var(--space-4x);
  font-weight: bold;
  font-size: var(--fontSize-lg);
}

.loading {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
  color: var(--color-disabled-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.loading:hover {
  cursor: not-allowed;
}
.loader {
  color: var(--color-primary);
  width: 22px;
  height: 22px;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
