.root {
  overflow: hidden;
}
.items {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--space) * -1);
  margin-right: calc(var(--space) * -1);
  margin-top: calc(var(--space-2x) * -1);
}
.items > li {
  padding-left: var(--space);
  padding-right: var(--space);
  padding-top: var(--space-2x);
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  width: 100%;
  height: 24px;
  font-size: var(--fontSize-sm);
  border-radius: 14px;
  white-space: nowrap;
  padding-left: var(--space-2x);
  padding-right: var(--space-2x);
}

@media screen and (max-width: 767px) {
  .root {
    width: 100%;
  }
  .item {
    min-width: 0;
    padding-left: var(--space);
    padding-right: var(--space);
  }
}

@media screen and (max-width: 374px) {
  .items {
    margin-left: calc(var(--space-2) * -1);
    margin-right: calc(var(--space-2) * -1);
    margin-top: calc(var(--space) * -1);
  }
  .items > li {
    padding-left: var(--space-2);
    padding-right: var(--space-2);
    padding-top: var(--space);
  }
  .item {
    font-size: var(--fontSize-xs);
    height: 22px;
  }
}
