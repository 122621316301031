.root {
  margin-bottom: var(--space-4x);
}
.contentRoot {
  border: 1px solid var(--color-gray-30);
  border-radius: var(--rounded);
  padding: var(--space-6x) var(--space-4x);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.clickeable {
  cursor: pointer;
}

.label {
}

.spacer {
  width: 50px;
}
