.root {
  position: absolute;
  margin-top: var(--space-3x);
  overflow: auto;
  overscroll-behavior: none;
  box-shadow: var(--shadow);
  border-radius: var(--rounded);
  opacity: 0;
}
.z-index--dropdown {
  z-index: var(--z-index-dropdown);
}
.z-index--follow {
  z-index: var(--z-index-follow);
}
.z-index--float {
  z-index: var(--z-index-float);
}
.size--sm .inner {
  min-width: 280px;
}
.size--md .inner {
  min-width: 320px;
}
.size--lg .inner {
  min-width: 800px;
}
.fixed {
  position: fixed;
}
.noPadding .inner {
  padding: 0;
}
.inner {
  background-color: var(--bg-white);
  min-width: 280px;
  padding: var(--space-6x);
}

@media screen and (max-width: 1024px) {
  .root {
    margin-top: 0;
  }
}
