.root {
  display: flex;
  flex-shrink: 0;
}

.item {
  flex-shrink: initial;
}

.direction--row {
  flex-direction: row;
}
.direction--row-reverse {
  flex-direction: row-reverse;
}
.direction--column {
  flex-direction: column;
}
.direction--column-reverse {
  flex-direction: column-reverse;
}

.align--start {
  align-items: flex-start;
}
.align--center {
  align-items: center;
}
.align--end {
  align-items: flex-end;
}
.align--stretch {
  align-items: stretch;
}
.align--baseline {
  align-items: baseline;
}
.align--normal {
  align-items: normal;
}

.justify--start {
  justify-content: flex-start;
}
.justify--center {
  justify-content: center;
}
.justify--end {
  justify-content: flex-end;
}
.justify--between {
  justify-content: space-between;
}

.spacing--1.direction--row > .item:not(:first-child) {
  margin-left: var(--space-1);
}
.spacing--1.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-1);
}
.spacing--1.direction--column > .item:not(:first-child) {
  margin-top: var(--space-1);
}
.spacing--1.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-1);
}
.spacing--2.direction--row > .item:not(:first-child) {
  margin-left: var(--space-2);
}
.spacing--2.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-2);
}
.spacing--2.direction--column > .item:not(:first-child) {
  margin-top: var(--space-2);
}
.spacing--2.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-2);
}
.spacing--base.direction--row > .item:not(:first-child) {
  margin-left: var(--space);
}
.spacing--base.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space);
}
.spacing--base.direction--column > .item:not(:first-child) {
  margin-top: var(--space);
}
.spacing--base.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space);
}
.spacing--2x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-2x);
}
.spacing--2x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-2x);
}
.spacing--2x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-2x);
}
.spacing--2x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-2x);
}
.spacing--3x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-3x);
}
.spacing--3x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-3x);
}
.spacing--3x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-3x);
}
.spacing--3x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-3x);
}
.spacing--4x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-4x);
}
.spacing--4x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-4x);
}
.spacing--4x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-4x);
}
.spacing--4x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-4x);
}
.spacing--5x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-5x);
}
.spacing--5x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-5x);
}
.spacing--5x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-5x);
}
.spacing--5x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-5x);
}
.spacing--6x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-6x);
}
.spacing--6x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-6x);
}
.spacing--6x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-6x);
}
.spacing--6x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-6x);
}
.spacing--8x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-8x);
}
.spacing--8x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-8x);
}
.spacing--8x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-8x);
}
.spacing--8x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-8x);
}
.spacing--10x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-10x);
}
.spacing--10x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-10x);
}
.spacing--10x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-10x);
}
.spacing--10x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-10x);
}
.spacing--12x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-12x);
}
.spacing--12x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-12x);
}
.spacing--12x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-12x);
}
.spacing--12x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-12x);
}
.spacing--14x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-14x);
}
.spacing--14x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-14x);
}
.spacing--14x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-14x);
}
.spacing--14x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-14x);
}
.spacing--16x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-16x);
}
.spacing--16x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-16x);
}
.spacing--16x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-16x);
}
.spacing--16x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-16x);
}
.spacing--20x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-20x);
}
.spacing--20x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-20x);
}
.spacing--20x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-20x);
}
.spacing--20x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-20x);
}
.spacing--30x.direction--row > .item:not(:first-child) {
  margin-left: var(--space-30x);
}
.spacing--30x.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-30x);
}
.spacing--30x.direction--column > .item:not(:first-child) {
  margin-top: var(--space-30x);
}
.spacing--30x.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-30x);
}

.fulledChild.direction--row > .item,
.fulledChild.direction--row-reverse > .item {
  height: 100%;
}
.fulledChild.direction--column > .item,
.fulledChild.direction--column-reverse > .item {
  width: 100%;
}

.item--fulled {
  flex-shrink: inherit;
  flex-basis: 100%;
}
.item--wrap {
  flex-shrink: inherit;
}
