.root {
  --common-padding: 12px;
  --thumb-size: 18px;
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: calc(var(--thumb-size) / 2);
  margin-bottom: calc(var(--thumb-size) / 2);
  background-color: var(--color-gray-10);
  border-radius: 100px;
}

.slider {
  position: relative;
  z-index: 1;
  height: 4px;
  margin-left: var(--common-padding);
  margin-right: var(--common-padding);
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-gray-10);
}

.slider > .range {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: var(--color-primary);
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: 50%;
  background-color: var(--color-primary);
}

.slider > .thumb.active {
  box-shadow: 0px 0px 0px 6px rgba(0, 205, 104, 0.2);
}

.slider > .thumb.left {
  transform: translate(-12px, -6px);
}

.slider > .thumb.right {
  transform: translate(12px, -6px);
}

.gray {
  background-color: var(--color-gray-30);
}

.root input[type="range"] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 4px;
  width: calc(100% - var(--common-padding) * 2 + var(--thumb-size));
  margin-left: calc(var(--common-padding) - var(--thumb-size) / 2);
  margin-right: calc(var(--common-padding) - var(--thumb-size) / 2);
  opacity: 0;
}

.root input[type="range"]::-webkit-slider-thumb {
  cursor: grab;
  pointer-events: all;
  border-radius: 50%;
  scale: 1.5;
}

.root.rootActive input[type="range"]::-webkit-slider-thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
