.root {
  padding: var(--space-4x) var(--space-6x);
  width: 392px;
}
.row {
  display: flex;
  align-items: flex-start;
  padding-top: var(--space-4x);
  padding-bottom: var(--space-4x);
  border-bottom: 1px solid var(--border-gray);
}
.row--vertical .rowDefinition {
  flex-direction: column;
  align-items: flex-start;
}
.row--vertical .rowDefinition > dd {
  width: 100%;
  margin-top: var(--space-2x);
}
.rowIcon {
  flex-shrink: 0;
  padding-top: 5px;
}
.rowBody {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;
  padding-left: var(--space-4x);
}
.rowDefinition {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rowDefinition > dt > * + * {
  margin-top: var(--space-2);
}

.rowChild {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rowChild + .rowChild {
  margin-top: var(--space-4x);
}
.note {
  margin-top: var(--space-4x);
}
.noteItem {
  display: flex;
  align-items: flex-start;
}
.noteIcon {
  flex-shrink: 0;
}
.noteBody {
  padding-top: 3px;
}
.noteIcon {
  font-size: var(--fontSize-base);
}

@media screen and (max-width: 1024px) {
  .root {
    max-width: 392px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
