.root {
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--space-10x);
  margin-bottom: var(--space-8x);
}

.ticketWrapper {
  width: 100%;
  height: 282px;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
