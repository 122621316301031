.root {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes addWishlist {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.variant--pressed {
  animation: 180ms ease-in-out 0s addWishlist;
}

.variant--default.stroke--black > svg > path {
  fill: var(--color-black);
}
.variant--default.stroke--white > svg > path:first-child {
  fill: var(--color-black);
  opacity: 0.3;
}
.variant--default.stroke--white > svg > path:last-child {
  fill: var(--color-white);
}
.variant--pressed.stroke--black > svg > path:first-child,
.variant--pressed.stroke--white > svg > path:first-child {
  fill: var(--color-red-60);
}
.variant--pressed.stroke--black > svg > path:last-child,
.variant--pressed.stroke--white > svg > path:last-child {
  fill: var(--color-red-5);
}

.size--sm > svg {
  width: 16px;
  height: 16px;
}
.size--md > svg {
  width: 24px;
  height: 24px;
}
.size--lg > svg {
  width: 32px;
  height: 32px;
}
.size--xl > svg {
  width: 48px;
  height: 48px;
}

@media (min-width: 768px) {
  .root:hover {
    background-color: var(--color-gray-5);
    border-radius: 50%;
    opacity: 0.8;
  }

  .variant--default.stroke--white:hover > svg > path {
    fill: var(--color-black);
  }
}