.title {
  padding: var(--space-3x);
  background-color: var(--color-gray-10);
  border-radius: var(--rounded-xs);
  margin-bottom: var(--space-4x);
}

.inner {
  white-space: break-spaces;
}

.item--vertical {
  display: block;
}

.item--vertical :global(div:first-child) {
  padding-bottom: var(--space-2x);
}

.item--horizontal {
  display: flex;
  align-items: center;
}

.item--horizontal :global(div:first-child) {
  width: 160px;
  padding-right: var(--space-6x);
}

.item-inner {
  display: flex;
  flex: 1;
}
