:root {
  /* Colors */
  --color-white: #ffffff;
  --color-black: #000000;
  --color-transparent: rgba(255, 255, 255, 0);

  --color-green-5: #e4f8ea;
  --color-green-10: #bdedca;
  --color-green-20: #90e2a8;
  --color-green-30: #58d783;
  --color-green-40: #00cd68;
  --color-green-50: #00c349;
  --color-green-60: #00b33f;
  --color-green-70: #00a032;
  --color-green-80: #008f25;
  --color-green-90: #006e0f;

  --color-red-5: #ffe9ee;
  --color-red-10: #ffc8d1;
  --color-red-20: #f39196;
  --color-red-30: #f86363;
  --color-red-40: #f53649;
  --color-red-50: #fa052c;
  --color-red-60: #eb002c;
  --color-red-70: #da0027;
  --color-red-80: #cd001f;
  --color-red-90: #bd0012;

  --color-yellow-5: #fffde7;
  --color-yellow-10: #fff9c4;
  --color-yellow-20: #fff59d;
  --color-yellow-30: #fff176;
  --color-yellow-40: #ffee58;
  --color-yellow-50: #ffeb3b;
  --color-yellow-60: #fdd835;
  --color-yellow-70: #fbc02d;
  --color-yellow-80: #f9a825;
  --color-yellow-90: #f57f17;

  --color-orange-5: #fcf5dc;
  /*
  --color-orange-10: #000000;
  --color-orange-20: #000000;
  --color-orange-30: #000000;
  */
  --color-orange-40: #f3bf3d;
  /*
  --color-orange-50: #000000;
  --color-orange-60: #000000;
  */
  --color-orange-70: #f27100;
  /*
  --color-orange-80: #000000;
  --color-orange-90: #000000;
  */

  --color-blue-5: #e3f2fd;
  --color-blue-10: #bbdefb;
  --color-blue-20: #90caf9;
  --color-blue-30: #64b5f6;
  --color-blue-40: #42a5f5;
  --color-blue-50: #2196f3;
  --color-blue-60: #1e88e5;
  --color-blue-70: #1976d2;
  --color-blue-80: #1565c0;
  --color-blue-90: #0d47a1;

  --color-gray-5: #f6f6f6;
  --color-gray-10: #eeeeee;
  --color-gray-20: #e2e2e2;
  --color-gray-30: #cbcbcb;
  --color-gray-40: #afafaf;
  --color-gray-50: #757575;
  --color-gray-60: #545454;
  --color-gray-70: #333333;
  --color-gray-80: #1f1f1f;
  --color-gray-90: #141414;

  --color-red: var(--color-red-40);
  --color-red-light: var(--color-red-5);
  --color-red-dark: var(--color-red-60);
  --color-blue: var(--color-blue-70);
  --color-blue-light: var(--color-blue-5);
  --color-blue-dark: var(--color-blue-80);
  --color-yellow: var(--color-yellow-70);
  --color-yellow-light: var(--color-yellow-5);
  --color-yellow-dark: var(--color-yellow-80);
  --color-green: var(--color-green-40);
  --color-green-light: var(--color-green-5);
  --color-green-darK: var(--color-green-60);
  --color-primary: var(--color-green-40);
  --color-primary-light: var(--color-green-5);
  --color-primary-dark: var(--color-green-60);
  --color-default: var(--color-gray-80);
  --color-default-dark: var(--color-black);
  --color-warning: var(--color-orange-70);
  --color-warning-light: var(--color-orange-5);
  --color-warning-dark: var(--color-orange-80);
  --color-danger: var(--color-red-40);
  --color-danger-light: var(--color-red-5);
  --color-danger-dark: var(--color-red-60);
  --color-disabled: var(--color-gray-30);
  --color-disabled-dark: var(--color-gray-50);
  --color-disabled-light: var(--color-gray-10);
  --color-focus: var(--color-blue-10);
  --color-link: var(--color-blue-80);

  /* Special */
  --color-positiveRed: var(--color-red-30);

  /* Background */
  --bg-primary: var(--color-primary);
  --bg-white: var(--color-white);
  --bg-gray: var(--color-gray-5);
  --bg-lottery-gradient: linear-gradient(
    to bottom,
    #fcc7eb,
    #f6cff0 40%,
    #ffe6fb
  );
  --bg-lottery: #ffe6fb;

  /* Border */
  --border-gray-dark: var(--color-gray-30);
  --border-gray-light: var(--color-gray-20);
  --border-gray: var(--color-gray-10);

  /* Shadows */
  --shadow: 0px 4px 8px rgba(26, 14, 0, 0.04),
    0px 0px 1px rgba(26, 14, 0, 0.04), 0px 0px 1px rgba(26, 14, 0, 0.04);
  --shadow-dark: 0px 4px 4px rgba(0, 0, 0, 0.05);
  --shadow-box: 0px 4px 16px rgba(26, 14, 0, 0.14),
    0px 0px 1px rgba(26, 14, 0, 0.04), 0px 0px 1px rgba(26, 14, 0, 0.04);

  /* Font Family */
  --font-sans: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

  /* Font Size */
  --fontSize-xs: 10px;
  --fontSize-sm: 12px;
  --fontSize-base: 14px;
  --fontSize-lg: 16px;
  --fontSize-xl: 18px;
  --fontSize-2xl: 20px;
  --fontSize-3xl: 24px;
  --fontSize-4xl: 28px;
  --fontSize-5xl: 32px;

  /* Font Line Height */
  --lineHeight-sm: 16px;
  --lineHeight-base: 20px;
  --lineHeight-lg: 24px;
  --lineHeight-xl: 24px;
  --lineHeight-2xl: 28px;
  --lineHeight-3xl: 32px;
  --lineHeight-4xl: 40px;
  --lineHeight-5xl: 44px;
  --lineHeight-6xl: 48px;

  /* Letter Spacing */
  --letter-spacing: 10%;

  /* Radius */
  --rounded: 8px;
  --rounded-xs: 4px;
  --rounded-md: 16px;
  --rounded-lg: 24px;
  --rounded-full: 100%;

  /* Spacing */
  --space-1: 1px;
  --space-2: 2px;
  --space: 4px;
  --space-2x: 8px;
  --space-3x: 12px;
  --space-4x: 16px;
  --space-5x: 20px;
  --space-6x: 24px;
  --space-8x: 32px;
  --space-9x: 36px;
  --space-10x: 40px;
  --space-11x: 44px;
  --space-12x: 48px;
  --space-13x: 52px;
  --space-14x: 56px;
  --space-16x: 64px;
  --space-18x: 72px;
  --space-20x: 80px;
  --space-30x: 120px;

  /* Animation */
  --duration: 0.24s;
  --duration-fast: 0.16s;
  --duration-lazy: 0.32s;

  /* Others */
  --header-height-pc: 56px;
  --header-height-tablet: 56px;
  --header-height-mobile: 40px;

  --z-index-toast: 110;
  --z-index-modal: 100;
  --z-index-header: 80;
  --z-index-dropdown: 50;
  --z-index-follow: 20;
  --z-index-float: 10;
}
