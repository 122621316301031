/* Figma  https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=3565%3A9588 */

.root {
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--rounded-xs);
  display: inline-block;
  white-space: nowrap;
}

.root > i {
  margin-right: var(--space);
  margin-bottom: var(--space-2);
}

/* Primary */
.primary--solid {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.primary--outline {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.primary--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-primary);
}

/*  Default */
.default--solid {
  background-color: var(--color-default);
  border-color: var(--color-default);
  color: var(--color-white);
}

.default--outline {
  background-color: var(--color-white);
  border-color: var(--color-default);
  color: var(--color-default);
}

.default--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-default);
}

/*  White */
.white--outline {
  background-color: var(--color-white);
  border-color: var(--color-gray-20);
}

/*  Danger */
.danger--solid {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
}

.danger--outline {
  background-color: var(--color-white);
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.danger--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-danger);
}

/* Disabled */
.disabled--solid {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
  color: var(--color-disabled-dark);
}

.disabled--outline {
  background-color: var(--color-white);
  border-color: var(--color-disabled-dark);
  color: var(--color-disabled-dark);
}

.disabled--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-disabled-dark);
}

/*  Positive Red */
.positiveRed--solid {
  background-color: var(--color-positiveRed);
  border-color: var(--color-positiveRed);
  color: var(--color-white);
}

.positiveRed--outline {
  background-color: var(--color-white);
  border-color: var(--color-positiveRed);
  color: var(--color-positiveRed);
}

.positiveRed--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-positiveRed);
}

/*  gray-50 */
.gray-50--solid {
  background-color: var(--color-gray-50);
  border-color: var(--color-gray-50);
  color: var(--color-white);
}

.gray-50--outline {
  background-color: var(--color-white);
  border-color: var(--color-gray-50);
  color: var(--color-gray-50);
}

.gray-50--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-gray-50);
}

/*  blue */
.blue--solid {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
  color: var(--color-white);
}

.blue--outline {
  background-color: var(--color-white);
  border-color: var(--color-blue);
  color: var(--color-blue);
}

.blue--ghost {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-blue);
}

/* Size */

.size--base {
  font-size: var(--fontSize-sm);
  line-height: calc(var(--space-6x) - 2px);
  padding: 0 calc(var(--space-2x) - 1px);
}
.size--sm {
  font-size: var(--fontSize-xs);
  line-height: calc(var(--space-5x) - 2px);
  padding: 0 calc(var(--space-2x) - 1px);
}
