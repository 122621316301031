.root {
  appearance: none;
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  transition: var(--duration);
}
.root:hover {
  opacity: 0.8;
}
.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-color: var(--color-primary);
  border-radius: 100%;
}
.hasBadge {
  position: relative;
}

.hasBadge::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--color-danger);
  border-radius: 100%;
  top: 0;
  right: 0;
}

.profileImage {
  border-radius: 50%;
}

.body {
  padding-left: var(--space-3x);
  padding-right: var(--space);
}
.dropdownContainer {
  min-width: 340px;
}
.badge {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 1024px) {
  .avatar {
    background: none;
    width: auto;
    height: auto;
  }

  .root {
    display: none;
  }
}
