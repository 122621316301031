.root {
  padding: var(--space-4x);
}

.root.card {
  border-radius: var(--rounded);
}

.variant--normal {
  background: var(--color-yellow-light);
}

.variant--warning {
  background: var(--color-red-light);
}

.variant--positive {
  background: var(--color-primary-light);
}

.variant--normal.normal {
  border-left: 8px solid var(--color-orange-40);
}

.variant--warning.normal {
  border-left: 8px solid var(--color-danger-dark);
}

.variant--positive.normal {
  border-left: 8px solid var(--color-primary);
}

.titleContainer {
  display: flex;
  align-items: center;
}

.iconWrapper {
  display: grid;
  place-content: center;
}

.title {
  margin-left: var(--space-2x);
}

.title.card p {
  white-space: nowrap;
}

.body {
  margin-top: var(--space);
}
