.progress {
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
}
.overall {
  font-weight: bold;
}
.outer {
  display: flex;
  margin-top: var(--space);
}
.inner {
  width: 100%;
  background-color: var(--color-gray-10);
  border-radius: 100px;
}
.background {
  background-color: var(--color-primary);
  border-radius: 100px;
}
