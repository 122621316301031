.point {
  display: block;
  border: 1px solid var(--color-gray-20);
  padding: var(--space-3x);
  border-radius: var(--rounded);
}

.pointTitle {
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-xl);
  font-weight: bold;
  margin-bottom: var(--space-2x);
  display: flex;
  align-items: center;
  gap: var(--space);
}

.pointAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-4x);
}

.pointAmountLabel {
  color: var(--color-gray-50);
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
  font-weight: bold;
  white-space: nowrap;
}

.pointAmountContent {
  display: flex;
  align-items: center;
  gap: var(--space-2x);
  white-space: nowrap;
}

.pointAmountValue {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: var(--space);
}

.pointAmountValue span {
  font-size: var(--fontSize-3xl);
  line-height: var(--lineHeight-4xl);
}

.pointSubAmountContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-2x);
}

.pointSubAmount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-2x);
}

.pointSubAmountContent {
  display: flex;
  align-items: center;
  gap: var(--space-2x);
}

.pointSubAmountValue {
  font-size: var(--fontSize-xs);
  line-height: var(--lineHeight-sm);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: var(--space);
  white-space: nowrap;
}

.pointSubAmountValue span {
  font-size: var(--fontSize-xl);
  line-height: var(--lineHeight-3xl);
}

.pointLink {
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
  font-weight: bold;
  color: inherit;
  width: fit-content;
  margin: 0 0 0 auto;
}

.alert {
  padding: var(--space-3x);
  margin: var(--space-2x) 0 var(--space-3x);
}
