.root {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: calc(var(--z-index-header) + 1);
  background-color: var(--color-white);
  transition: background-color var(--duration-fast);
}

.transparent {
  background-color: var(--color-transparent);
}

.noFollow {
  position: relative;
}

.shadow {
  filter: drop-shadow(0px 4px 8px rgba(26, 14, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(26, 14, 0, 0.04))
    drop-shadow(0px 0px 1px rgba(26, 14, 0, 0.04));
}

.inner {
  height: var(--header-height-pc);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-4x);
}

.main {
  margin-left: 0;
  margin-right: var(--space-6x);
  margin-top: var(--space);
}

.brand {
  position: relative;
  z-index: 1;
}

.action {
  flex-shrink: 0;
  flex: 1;
}

.sub {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .inner {
    height: var(--header-height-tablet);
  }
  .main {
    margin-left: var(--space-4x);
  }
  .inner {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .main {
    margin-right: 0;
  }
  .inner {
    height: var(--header-height-mobile);
  }
}
