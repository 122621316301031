.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  white-space: nowrap;
}

.root.disabled {
  opacity: 0.7;
}

button.root {
  border: none;
  background: none;
}

.icon img {
  width: 100%;
  height: 100%;
}

/* SIZE */
.size--md {
  height: var(--space-10x);
}

.size--md .icon {
  width: 40px;
  height: 40px;
}

.size--lg {
  height: var(--space-14x);
}

.size--lg .icon {
  width: 48px;
  height: 48px;
}
