.root + .root {
  margin-top: var(--space-20x);
}

.first {
  display: flex;
}

.imageWrapper {
  margin-right: var(--space-10x);
}

.imageWrapper > i {
  width: 240px;
  height: 240px;
}
.image {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: cover;
}

.content {
  flex: 1;
}
.description {
  margin-top: var(--space-2x);
}

@media (max-width: 1024px) {
  .root + .root {
    margin-top: var(--space-8x);
  }

  .imageWrapper > i {
    width: 180px;
    height: 180px;
  }

  .image {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 767px) {
  .root + .root {
    margin-top: var(--space-10x);
  }

  .imageWrapper {
    margin-right: var(--space-4x);
  }

  .imageWrapper > i {
    width: 80px;
    height: 80px;
  }

  .image {
    width: 80px;
    height: 80px;
  }

  .second {
    margin-top: var(--space-4x);
  }
}
