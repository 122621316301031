.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 var(--space-6x);
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  white-space: nowrap;
  outline: none;
  border-radius: var(--rounded-lg);
  background-color: var(--color-white);
  border-color: var(--color-default);
  color: var(--color-default);
  font-size: var(--fontSize-lg);
  height: var(--space-12x);
  width: 100%;
}

.icon {
  position: absolute;
  left: var(--space-5x);
  display: flex;
  align-items: center;
}

.apple {
  background-color: var(--color-black);
  color: var(--color-white);
}