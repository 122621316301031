.root {
  position: relative;
  height: auto;
  overflow: hidden;
}

.title {
  position: absolute;
  color: var(--color-white);
  bottom: 0;
  left: 0;
  font-size: var(--fontSize-sm);
  font-weight: bold;
  line-height: 1.5;
  margin: 8px;
}

@keyframes spinLoader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 2px solid var(--color-primary);
  border-bottom-color: rgba(255, 255, 255, 0);
  animation: spinLoader 1s linear infinite;
}

.img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
  backface-visibility: hidden;
}
