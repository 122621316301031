.scene {
  width: 180px;
  height: 255px;
  position: relative;
  perspective: 1000px;
  margin: 0 auto;
}

.card {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotateZ(15deg);
  transform-style: preserve-3d;
  transform-origin: center;
  transition:
    transform 0.1s,
    filter 0.1s; /* Add filter transition */
}

.card.is-dragging {
  animation: none;
}

.card__face {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 12px;
}

.card__front,
.card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 12px;
}

.card__front {
  background-size: cover;
  background-position: center;
}

.card__back {
  background-size: cover;
  background-position: center;
  transform: rotateY(180deg);
}
