.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 280px;
  padding: var(--space-2x) var(--space-6x);
  height: 56px;
  border-radius: 56px;
  border: 1px solid var(--bd-gray);
  box-shadow: 2px 2px 4px rgba(26, 14, 0, 0.04);
  background-color: #fff;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: var(--space-2x);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-primary);
  border-radius: 100%;
}
