.fieldWrapper {
  width: 375px;
}

.destinationWrapper {
  max-height: 430px;
  overflow-y: scroll;
}

.pcActionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3x) var(--space-5x);
}

.clearButton {
  min-width: 110px;
  cursor: pointer;
}

.clearButton:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1024px) {
  .fieldWrapper {
    width: unset;
  }

  .destinationWrapper {
    max-height: unset;
  }

  .pcActionWrapper {
    display: none;
  }

  .spActionWrapper {
    display: flex;
  }

  .spActionWrapper > li + li {
    margin-left: var(--space-4x);
    width: 100%;
  }
}
