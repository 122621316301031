.root {
  display: flex;
  flex: 1;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--rounded);
  padding: var(--space-3x);
  gap: var(--space-3x);
  width: 328px;
}

.root dl {
  width: 100%;
}

.thumbnail {
  width: 96px;
  height: 96px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: var(--rounded);
  object-fit: cover;
}

.details {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}

.details dl span {
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .root {
    width: 280px;
  }

  .thumbnail {
    width: 84px;
    height: 84px;
  }

  .details dl span {
    line-height: 20px;
  }
}
