.root {
  display: block;
  cursor: pointer;
  position: relative;
}

.variant--laptop {
  border-radius: var(--rounded);
  box-shadow: var(--shadow-box);
  padding: var(--space-6x);
}

.variant--mobile {
  padding: var(--space-2x) 0;
}
