.root {
  display: block;
  border: 2px solid var(--border-gray);
  border-radius: var(--rounded);
  width: 100%;
  color: inherit;
}
.root:not(:last-child) {
  margin-bottom: var(--space-6x);
}

.root.highlight {
  border: 1px solid var(--color-primary);
}

.highlightHeader {
  border-radius: var(--rounded) var(--rounded) 0 0;
  display: flex;
  align-items: center;
  padding: var(--space-3x);
  background-color: var(--color-green-5);
}
.highlightHeader > i {
  margin-right: var(--space-4x);
}

.inner {
  display: flex;
  width: 100%;
  padding: var(--space-4x) var(--space-4x) var(--space-4x) var(--space-4x);
}
.thumbnail {
  position: relative;
  width: 300px;
  flex-shrink: 0;
}
.thumbnailInner {
  position: relative;
  height: 100%;
  min-height: 200px;
  overflow: hidden;
  border-radius: var(--rounded);
}
.thumbnail .mainImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.labels {
  position: absolute;
  padding: var(--space-2x);
  z-index: 1;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.wishlistButtonWrapper {
  position: absolute;
  margin: var(--space);
  top: 0;
  right: 0;
  z-index: 1;
}
.stayInfo {
  position: absolute;
  bottom: var(--space-2x);
  padding-left: var(--space-2x);
  display: flex;
  width: 100%;
  z-index: var(--z-index-float);
  font-size: var(--fontSize-sm);
  color: var(--color-white);
  font-weight: bold;
}
.stayInfo > :first-child {
  margin-right: var(--space-2x);
}
.stayInfo > :nth-child(2) {
  max-width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stayInfo > :nth-child(3) span {
  margin-left: var(--space-2x);
  margin-right: var(--space);
}
.thumbnail .imageCover {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: var(--rounded);
}
.detail {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  width: calc(100% - 300px);
  padding-left: var(--space-6x);
}
.title {
  font-weight: bold;
  font-size: var(--fontSize-lg);
  line-height: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.items {
  margin-top: var(--space-2x);
}
.items > li:first-child {
  margin-bottom: var(--space);
}
.item {
  display: flex;
  align-items: center;
}
.itemIcon {
  flex-shrink: 0;
}
.itemIcon + .itemBody {
  padding-left: var(--space-2x);
  width: calc(100% - 16px);
}
.hotel {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  gap: var(--space-2x);
}
.hotelRating {
  flex-shrink: 0;
}
.hotelNameWrap {
  display: flex;
  align-items: center;
  gap: var(--space);
}
.hotelName {
  flex: 1 0 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.footer {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-2x);
}

.flexibleSearchInfo {
  margin-top: var(--space-2x);
  display: flex;
  align-items: center;
  gap: var(--space-2x);
}

.searchInfo,
.flexibleSearchInfo {
  color: var(--color-gray-50);
  font-weight: bold;
  font-size: var(--fontSize-xs);
}
.flexibleSearchInfo .flexibleTravelDates {
  border: 1px dashed var(--color-gray-50);
  padding: var(--space) var(--space-2x);
  border-radius: var(--rounded-xs);
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.price {
  font-size: var(--fontSize-2xl);
  line-height: var(--lineHeight-3xl);
  font-weight: bold;
}

.action {
  width: 140px;
}

.freeCancel {
  color: var(--color-primary);
  font-size: var(--fontSize-sm);
  font-weight: bold;
}

.freeCancelForPC {
  display: flex;
  align-items: center;
  gap: var(--space);
  margin-top: var(--space-3x);
}

.freeCancelForSP {
  display: none;
}

.airlineInfo {
  display: flex;
  align-items: center;
  gap: var(--space);
}

.airlineName {
  min-width: 0;
}

.airlineImage {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.flightTime {
  width: 28px;
  padding-left: var(--space);
  padding-right: var(--space);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flightTimeWrapper {
  white-space: nowrap;
}

.flightInfoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--space-2x);
}

.airlineSection {
  display: flex;
  align-items: center;
  gap: var(--space);
  min-width: 0;
  flex: 0 1 auto;
}

.flightDetailsSection {
  display: flex;
  align-items: center;
  gap: var(--space-2x);
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .root {
    border: 0;
    padding-top: var(--space-4x);
    padding-bottom: var(--space-4x);
  }
  .root.highlight {
    border: 0;
  }
  .root:not(:last-child) {
    margin-bottom: 0;
    border-bottom: 1px solid var(--border-gray);
  }

  .inner {
    flex-direction: column;
    padding: 0;
  }
  .thumbnail {
    width: auto;
  }
  .thumbnailInner {
    min-height: auto;
    height: 145px;
  }
  .highlight .thumbnailInner {
    border-radius: 0 0 var(--rounded) var(--rounded);
  }

  .detail {
    width: auto;
    padding-left: 0;
    margin-top: var(--space-2x);
  }
  .title {
    font-size: var(--fontSize-base);
    line-height: var(--lineHeight-lg);
  }
  .items {
    margin-top: var(--space-2x);
  }
  .items > li + li {
    margin-top: var(--space);
  }
  .footer {
    margin-top: var(--space);
    justify-content: flex-end;
  }

  .flexibleSearchInfo {
    justify-content: flex-end;
  }
  .footerBottom {
    justify-content: flex-end;
  }
  .footerBottom > .priceInfo {
    flex: 1;
  }
  .searchInfoSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--space);
  }
  .searchInfoSection > .searchInfo {
    margin-top: 0;
  }
  .price {
    font-size: var(--fontSize-xl);
    line-height: var(--lineHeight-3xl);
  }
  .priceLabel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--space);
  }
  .priceLabel > .searchInfo {
    margin-top: 0;
  }
  .action {
    display: none;
  }
  .bottomSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;
  }
  .bottomSection.isNotFreeCancel {
    justify-content: flex-end;
  }
  .freeCancelForPC {
    display: none;
  }
  .freeCancelForSP {
    display: flex;
    align-items: center;
    gap: var(--space);
  }

  .airlineSection {
    min-width: 0;
    flex: 0 1 auto;
  }

  .flightDetailsSection {
    flex-shrink: 0;
  }
}
