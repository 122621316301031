.root {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

.size--sm {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
}
.size--base {
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
}
.size--lg {
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-xl);
}
.size--xl {
  font-size: var(--fontSize-xl);
  line-height: var(--lineHeight-2xl);
}
.size--2xl {
  font-size: var(--fontSize-2xl);
  line-height: var(--lineHeight-3xl);
}
.size--3xl {
  font-size: var(--fontSize-3xl);
  line-height: var(--lineHeight-4xl);
}
.size--4xl {
  font-size: var(--fontSize-4xl);
  line-height: var(--lineHeight-5xl);
}
.size--5xl {
  font-size: var(--fontSize-5xl);
  line-height: var(--lineHeight-5xl);
}

.color--default {
  color: var(--color-gray-80);
}
.color--primary {
  color: var(--color-primary);
}
.color--danger {
  color: var(--color-red-40);
}
.color--warning {
  color: var(--color-warning);
}
.color--link {
  color: var(--color-blue-80);
}
.color--white {
  color: var(--color-white);
}
.color--black {
  color: var(--color-black);
}
.color--gray {
  color: var(--color-gray-50);
}
.color--disabled {
  color: var(--color-disabled);
}
.color--inherit {
  color: inherit;
}
.color--positiveRed {
  color: var(--color-positiveRed);
}

.weight--normal {
  font-weight: normal;
}
.weight--bold {
  font-weight: bold;
}

.lineHeight--lg {
  line-height: 2;
}

.align--left {
  text-align: left;
}
.align--center {
  text-align: center;
}
.align--right {
  text-align: right;
}
