.root {
  padding: var(--space-6x);
}

.remarksForLocalTax {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
  color: var(--color-gray-80);
  white-space: pre-wrap;
}
