.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-10x);
}
.subgrid {
  display: grid;
  grid-row: span 2;
  grid-template-rows: subgrid;
  gap: var(--space-4x);
}

.root.fullWidth {
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 1024px) {
  .root {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--space-8x);
  }
}
