.point {
  display: flex;
}
.point > span {
  font-size: var(--fontSize-sm);
  line-height: 1.2;
}
.point > span > span {
  font-weight: bold;
  margin-right: var(--space);
  margin-left: var(--space);
}
.pointDetail {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  cursor: pointer;
  color: var(--color-blue-80);
}

@media screen and (max-width: 767px) {
  .pricePoint {
    display: flex;
    justify-content: flex-end;
  }
}
