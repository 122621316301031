.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-radius: 88px;
  background-color: var(--color-white);
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  transition: var(--duration);
  width: 1032px;
}
.main {
  width: calc(100% - 80px);
  height: 100%;
}
.action {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-left: var(--space-4x);
  height: 100%;
  transition: var(--duration-fast);
}
.action button {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}
.actionIcon + .actionBody {
  padding-left: var(--space-2x);
}
.fields {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* transition: 0.08s var(--duration); */
}
.fields > li {
  width: 220px;
  position: relative;
  height: 100%;
  padding-left: var(--space-4x);
  padding-right: var(--space-4x);
}
.fields > li:nth-of-type(1) {
  width: 23%;
}
.fields > li:nth-of-type(2) {
  width: 25%;
}
.fields > li:nth-of-type(3) {
  width: 29%;
}
.fields > li:nth-of-type(4) {
  width: 23%;
}
.fields > li:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 48px;
  background-color: var(--border-gray-light);
}
.fields > li:last-child:before {
  content: none;
}

.fieldWrapper {
  width: 375px;
  max-height: 430px;
  overflow-y: scroll;
}

.actionIcon {
  display: inherit;
}
.actionBody {
  display: none;
}

@media screen and (max-width: 1024px) {
  .root {
    width: auto;
    height: auto;
    padding: 0;
    align-items: inherit;
    flex-direction: column;
    box-shadow: none;
  }
  .main {
    padding-right: var(--space-6x);
    padding-left: var(--space-6x);
    width: 100%;
  }
  .fields {
    flex-direction: column;
    align-items: inherit;
  }
  .fields > li {
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
  }
  .fields > li:before {
    left: var(--space-14x);
    top: auto;
    bottom: 0;
    right: 0;
    width: auto;
    height: 1px;
    transform: translate(0, -50%);
  }
  .fields > li:last-child:before {
    content: "";
  }

  .fieldWrapper {
    width: unset;
    max-height: unset;
  }
  .action {
    display: block;
    padding: var(--space-4x) var(--space-6x) 0 var(--space-6x);
  }
  .action button {
    width: 100%;
    height: 48px;
    border-radius: 48px;
  }
  .actionIcon {
    display: none;
  }
  .actionBody {
    display: inherit;
  }
}
