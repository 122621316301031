.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  font-size: 0;
}

.clickable {
  cursor: pointer;
}

.root svg {
  width: 100%;
  height: 100%;
}

.color--gray-80 > svg path {
  fill: var(--color-gray-80);
}
.color--gray-80 > svg circle {
  stroke: var(--color-gray-80);
}

.color--gray-50 > svg path {
  fill: var(--color-gray-50);
}
.color--gray-50 > svg circle {
  stroke: var(--color-gray-50);
}

.color--gray-30 > svg path {
  fill: var(--color-gray-30);
}
.color--gray-30 > svg circle {
  stroke: var(--color-gray-30);
}

.color--green-5 > svg path {
  fill: var(--color-green-5);
}
.color--green-5 > svg circle {
  stroke: var(--color-green-5);
}

.color--green-40 > svg path {
  fill: var(--color-green-40);
}
.color--green-40 > svg circle {
  stroke: var(--color-green-40);
}

.color--yellow-50 > svg path {
  fill: var(--color-yellow-50);
}
.color--yellow-50 > svg circle {
  stroke: var(--color-yellow-50);
}

.color--positiveRed > svg path {
  fill: var(--color-positiveRed);
}
.color--positiveRed > svg circle {
  stroke: var(--color-positiveRed);
}

.color--white > svg path {
  fill: var(--color-white);
}
.color--white > svg circle {
  stroke: var(--color-white);
}

.color--black > svg path {
  fill: var(--color-black);
}
.color--black > svg circle {
  stroke: var(--color-black);
}

.color--primary > svg path {
  fill: var(--color-primary);
}
.color--primary > svg circle {
  stroke: var(--color-primary);
}

.color--warning > svg path {
  fill: var(--color-warning);
}
.color--warning > svg circle {
  stroke: var(--color-warning);
}

.color--danger > svg path {
  fill: var(--color-danger);
}
.color--danger > svg circle {
  stroke: var(--color-danger);
}

.size--xs {
  width: 12px;
  height: 12px;
}
.size--sm {
  width: 16px;
  height: 16px;
}
.size--base {
  width: 20px;
  height: 20px;
}
.size--md {
  width: 24px;
  height: 24px;
}
.size--lg {
  width: 32px;
  height: 32px;
}
.size--xl {
  width: 48px;
  height: 48px;
}
.size--2xl {
  width: 56px;
  height: 56px;
}
