.root {
  padding: var(--space-6x) var(--space-3x) var(--space-5x) var(--space-5x);
}
.root:hover {
  opacity: 0.8;
}
.rootLabel {
  font-weight: bold;
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-6xl);
}
.areaRoot {
  border-bottom: 1px solid var(--border-gray-light);
}
