.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.inner {
  flex-grow: 1;
}

.sp {
  display: none;
}

.fixedAppBannerForPc {
  padding-bottom: 120px;
}

@media (max-width: 1024px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }

  .fixedAppBannerForPc {
    display: none;
    padding-bottom: unset;
  }
}

@media (max-width: 767px) {
  .hide {
    display: none;
  }
}
