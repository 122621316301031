.root {
  color: inherit;
}
.inner {
  width: 100%;
  overflow: hidden;
}
.thumbnail {
  position: relative;
  width: 100%;
  height: 160px;
  flex-shrink: 0;
}
.thumbnailInner {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: var(--rounded) var(--rounded) 0 0;
}
.thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  padding: var(--space-4x);
  height: 100px;
  line-height: var(--lineHeight-2xl);
  font-size: var(--fontSize-base);
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 78px;
}
.main {
  background-color: var(--color-white);
  border-radius: 0 0 var(--rounded) var(--rounded);
  height: 86px;
  border: 1px solid var(--color-gray-20);
}

@media screen and (max-width: 767px) {
  .thumbnail {
    height: 90px;
  }
  .title {
    padding: 0;
    height: 60px;
    font-size: var(--fontSize-sm);
    line-height: 20px;
  }
  .main {
    height: 76px;
    padding: 10px 8px;
  }
}
