.root {
  padding-top: var(--space-8x);
  padding-bottom: var(--space-8x);
}

.bgGray {
  background-color: var(--bg-gray);
}

.titleHasSideElement {
  display: flex;
  justify-content: space-between;
}

.description {
  white-space: pre-wrap;
}

@media screen and (max-width: 767px) {
  .root {
    padding-bottom: var(--space-6x);
  }
}
