.countryLabel {
  line-height: var(--lineHeight-4xl);
  font-size: var(--fontSize-base);
  font-weight: bold;
}

.citiesWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--space-2x);
  column-gap: var(--space-2x);
}
