.root {
  flex-shrink: 0;
  border-top: 1px solid var(--color-gray-10);
}

/* Vertical */
.vertical--1 {
  margin: var(--space-1) 0;
}

.vertical--2 {
  margin: var(--space-2) 0;
}
.vertical--base {
  margin: var(--space) 0;
}

.vertical--2x {
  margin: var(--space-2x) 0;
}

.vertical--3x {
  margin: var(--space-3x) 0;
}

.vertical--4x {
  margin: var(--space-4x) 0;
}

.vertical--5x {
  margin: var(--space-5x) 0;
}

.vertical--6x {
  margin: var(--space-6x) 0;
}

.vertical--8x {
  margin: var(--space-8x) 0;
}

.vertical--10x {
  margin: var(--space-10x) 0;
}

.vertical--12x {
  margin: var(--space-12x) 0;
}

.vertical--14x {
  margin: var(--space-14x) 0;
}

.vertical--16x {
  margin: var(--space-16x) 0;
}

.vertical--20x {
  margin: var(--space-20x) 0;
}

.vertical--30x {
  margin: var(--space-30x) 0;
}
