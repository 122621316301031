.root {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--space-4x);
}

.label {
  margin-bottom: var(--space);
}
