.root {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 120px;
  width: 100%;
  z-index: calc(var(--z-index-header) + 1);
}

.flex {
  display: flex;
}

.img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.nativeAppWrapper {
  position: relative;
  width: 171px;
  height: 120px;
}

.nativeAppWrapper .nativeApp {
  position: absolute;
  bottom: 0;
  left: 0;
}

.text {
  margin: 12px 0 20px var(--space-10x);
}

.action {
  flex-grow: 1;
  display: flex;
  position: relative;
  padding-bottom: var(--space-5x);
  justify-content: flex-end;
  align-items: flex-end;
}

.actionImg {
  margin-right: var(--space-4x);
}

.icon {
  position: inherit;
  justify-self: start;
  height: 100%;
  padding-top: 10px;
  margin-left: var(--space-4x);
}

@media screen and (min-width: 1200px) {
  .icon {
    position: absolute;
    top: 0px;
    right: -40px;
    cursor: pointer;
  }
}
