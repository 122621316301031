.areaLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  width: 100%;
  padding-top: var(--space-3x);
  padding-bottom: var(--space-3x);
  cursor: pointer;
  font-size: var(--fontSize-base);
  font-weight: bold;
}

.countryRoot {
  padding-bottom: var(--space-4x);
}

.countryLabel {
  line-height: var(--lineHeight-4xl);
  font-size: var(--fontSize-base);
  font-weight: bold;
}

.citiesWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--space-2x);
}

.citiesWrapper > li {
  margin-right: var(--space-2x);
}

.close {
  display: none;
}
.open {
  display: block;
}
@media screen and (max-width: 1024px) {
}
