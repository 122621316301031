.root {
  font-size: 0;
  white-space: nowrap;
  overflow-x: scroll;
}

@media screen and (min-width: 1025px) {
  .root {
    overflow-x: hidden;
  }
  .root > ul > li:not(:last-child) {
    flex-shrink: 0;
  }
  .root > ul > li:last-child {
    min-width: 0;
    flex: 1;
  }
  .root > ul > li:last-child span {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
