.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  flex-grow: 1;
  margin-top: var(--space-14x);
  margin-bottom: var(--space-10x);
}
