.root {
  position: relative;
  border-radius: var(--rounded);
  overflow: hidden;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}
