.root {
  margin: 0;
  padding: 0;
}

.shadowed {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}
.size--xs {
  font-size: var(--fontSize-xs);
  line-height: var(--lineHeight-xs);
}
.size--sm {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
}
.size--base {
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-base);
}
.size--lg {
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-lg);
}
.size--xl {
  font-size: var(--fontSize-xl);
  line-height: var(--lineHeight-xl);
}
.size--2xl {
  font-size: var(--fontSize-2xl);
  line-height: var(--lineHeight-2xl);
}
.size--3xl {
  font-size: var(--fontSize-3xl);
  line-height: var(--lineHeight-3xl);
}
.size--4xl {
  font-size: var(--fontSize-4xl);
  line-height: var(--lineHeight-4xl);
}
.size--5xl {
  font-size: var(--fontSize-5xl);
  line-height: var(--lineHeight-5xl);
}

.color--default {
  color: var(--color-gray-80);
}
.color--primary {
  color: var(--color-primary);
}
.color--danger {
  color: var(--color-red-40);
}
.color--link {
  color: var(--color-link);
}
.color--white {
  color: var(--color-white);
}
.color--black {
  color: var(--color-black);
}
.color--gray {
  color: var(--color-gray-50);
}
.color--disabled {
  color: var(--color-disabled);
}
.color--inherit {
  color: inherit;
}
.color--positiveRed {
  color: var(--color-positiveRed);
}

.weight--normal {
  font-weight: normal;
}
.weight--bold {
  font-weight: bold;
}

.spacing--normal {
  letter-spacing: 0;
}
.spacing--widest {
  letter-spacing: 0.1em;
}

.as--span {
  display: block;
}
.as--h1,
.as--h2,
.as--h3,
.as--h4,
.as--h5 {
  display: block;
}

.align--left {
  text-align: left;
}
.align--center {
  text-align: center;
}

.align--right {
  text-align: right;
}

.lineHeight--sm {
  line-height: var(--lineHeight-sm);
}
.lineHeight--base {
  line-height: var(--lineHeight-base);
}
.lineHeight--lg {
  line-height: var(--lineHeight-lg);
}
.lineHeight--xl {
  line-height: var(--lineHeight-xl);
}
.lineHeight--2xl {
  line-height: var(--lineHeight-2xl);
}
.lineHeight--3xl {
  line-height: var(--lineHeight-3xl);
}
.lineHeight--4xl {
  line-height: var(--lineHeight-4xl);
}
.lineHeight--5xl {
  line-height: var(--lineHeight-5xl);
}
.lineHeight--6xl {
  line-height: var(--lineHeight-6xl);
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lineThrough {
  text-decoration: line-through;
}

.underlineHover:hover {
  text-decoration: underline;
}
