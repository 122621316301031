body {
  background-color: var(--color-white);
}

@media screen and (max-width: 1024px) {
  iframe[tabindex="0"][title="メッセージングウィンドウ"] {
    height: 100% !important;
    max-height: none !important;
    width: 100% !important;
    inset: 0 !important;
  }
}
