.root {
  padding-top: var(--space-4x);
  padding-bottom: var(--space-4x);
}
.root:hover {
  opacity: 0.8;
}
.optionGroups > li + li {
  margin-top: var(--space-4x);
}
.optionGroup > dt {
  display: flex;
  align-items: center;
  padding: var(--space-2x) var(--space-5x);
}
.optionGroupLabel {
  font-weight: bold;
  font-size: var(--fontSize-lg);
  color: var(--color-default);
}
.options {
}
.options > li {
  position: relative;
}
.options.options--with-border > li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: var(--space-5x);
  right: var(--space-5x);
  margin: auto;
  height: 1px;
  background-color: var(--border-gray);
  overflow: hidden;
}
.options > li > ul {
  position: relative;
}
.options.options--with-border > li > ul:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: var(--space-5x);
  right: var(--space-5x);
  margin: auto;
  height: 1px;
  background-color: var(--border-gray);
  overflow: hidden;
}
.option {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 48px;
  width: 100%;
  padding: var(--space-2x) var(--space-10x) var(--space-2x) var(--space-5x);
  cursor: pointer;
}
.option:hover {
  opacity: 0.7;
}
.option--disabled {
  pointer-events: none;
}
.option--with-image {
}
.optionInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.optionInput:checked ~ .optionLabel:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 24px;
  width: 8px;
  height: 16px;
  border-bottom: 2px solid var(--color-primary);
  border-right: 2px solid var(--color-primary);
  transform: translate(-50%, -65%) rotate(45deg);
}
.optionImage {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}
.optionImage img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
}
.optionImage + .optionLabel {
  padding-left: var(--space-4x);
}
.optionLabel {
  flex-basis: 100%;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}
.optionCollapse {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  width: 100%;
  padding: var(--space-2x) var(--space-5x);
  cursor: pointer;
}
.optionCollapseListInActive {
  display: none;
}
.optionCollapseListActive {
  display: block;
}
@media screen and (max-width: 1024px) {
  .optionGroups > li + li {
    margin-top: var(--space-10x);
  }
  .optionGroup > dd {
    margin-top: var(--space-2x);
  }
  .optionImage {
    width: 64px;
    height: 64px;
    margin-left: calc(var(--space-5x) * -1);
  }
  .optionImage + .optionLabel {
    padding-left: var(--space-5x);
  }
  .noPadding {
    padding: 0;
  }
  .options.options--with-border.options--no-padding > li:after {
    left: 0;
    right: 0;
  }
}
