.root {
  position: relative;
  height: 0;
  background-color: var(--color-gray-20);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

.list {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  white-space: nowrap;
}
.list.is-animate {
  transition: transform 0.3s;
}

.image {
  position: relative;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
}
.image.is-active {
  z-index: 2;
}

.counter {
  position: absolute;
  bottom: var(--space-5x);
  right: var(--space-5x);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: var(--rounded-xs);
  background: rgba(0, 0, 0, 0.5);
  padding: 0 var(--space-2x);
  color: var(--color-white);
  font-size: var(--fontSize-sm);
  font-weight: bold;
  line-height: 1;
}

.photoLibraryIcon {
  margin-right: 4px;
}

.overlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
