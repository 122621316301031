.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: var(--space-2x) 0;
  position: relative;
}
.root:hover {
  background-color: var(--color-gray-5);
}
.icon + .body {
  padding-left: var(--space-4x);
}
