.root {
  background-color: #f6f6f6;
  padding-top: var(--space-10x);
  padding-bottom: var(--space-20x);
}

.nav {
  display: grid;
}

.navGroups {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.navGroupColumn {
  display: flex;
  border-bottom: 1px solid var(--border-gray-light);
  padding-bottom: var(--space-6x);
  margin-bottom: var(--space-6x);
}

.navGroupColumn:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.navGroupColumn > li:nth-child(n + 2) {
  margin-left: var(--space-30x);
}

.navGroup > dt {
  padding-bottom: var(--space-4x);
}

.navGroup > dd + dd {
  margin-top: var(--space-3x);
}

.navGroupItems {
  margin-top: var(--space-3x);
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2x) var(--space-4x);
  line-height: var(--lineHeight-base);
}

.navSubGroup {
  display: flex;
  width: 100%;
}

.navSubGroupItems {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-4x);
  line-height: var(--lineHeight-base);
}

.navSubGroup .navGroupItems {
  margin-top: 0;
}

.navSubGroupLabel {
  width: 180px;
  margin-right: var(--space-6x);
  font-weight: bold;
  flex-shrink: 0;
}

.directionColumn {
  flex-direction: column;
}

.directionRow {
  flex-direction: row;
}

.navLink {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
.navLink:hover {
  text-decoration: underline;
}

.bar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 72px;
  padding-top: var(--space-6x);
  border-top: 1px solid var(--border-gray);
}
.barNavLink {
  color: inherit;
  text-decoration: none;
}
.barNavLink:hover {
  text-decoration: underline;
}
.snsLink {
  display: block;
  width: 24px;
  height: 24px;
  text-decoration: none;
  cursor: pointer;
  transition: var(--duration);
  margin-top: var(--space-4x);
}
.snsLink:hover {
  opacity: 0.7;
}
.snsLink:active {
  opacity: 0.6;
}
.snsLink img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .root {
    padding-top: var(--space-10x);
    padding-bottom: var(--space-10x);
  }
  .navGroups {
    grid-template-columns: 1fr;
  }
  .navGroupColumn {
    margin-top: var(--space-4x);
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    flex-direction: column;
  }
  .navGroupColumn:first-child {
    margin-top: 0;
  }
  .navGroupColumn:last-child {
    margin-top: var(--space-6x);
  }
  .navGroupColumn > li:nth-child(n + 2) {
    margin-left: 0;
    margin-top: var(--space-8x);
  }
  .navGroupColumn > li > ul > li {
    width: 100%;
  }
  .navGroupItems {
    max-height: none;
    gap: var(--space-3x);
  }
  .navSubGroup {
    flex-direction: column;
  }
  .navSubGroupLabel {
    width: auto;
    margin-bottom: var(--space-2x);
  }
  .navSubGroupItems {
    gap: var(--space-6x);
  }
  .bar {
    margin-top: var(--space-12x);
    padding-top: var(--space-8x);
    display: block;
    border-top-color: var(--color-gray-20);
  }
  .copyright {
    margin-top: var(--space-8x);
  }
}
