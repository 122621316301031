.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.variant--default {
  background-color: var(--color-white);
  border-top: 1px solid var(--border-gray);
  border-bottom: 1px solid var(--border-gray);
}

.variant--primary {
  background-color: var(--color-primary-light);
  border-radius: var(--rounded-md);
}

.downloadContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: var(--space-8x);
  padding: var(--space-5x) var(--space-16x);
  width: 1032px;
  max-width: 100%;
}

.header {
  display: flex;
  gap: var(--space-9x);
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-4x);
}

.figure {
  display: flex;
  justify-content: center;
  height: 269px;
}

.appIconImg {
  width: 80px;
  height: 80px;
}

.headingImg {
  height: 54px;
  width: auto;
}

.action {
  height: 100%;
  margin: auto 0 var(--space-8x);
}

.downloadWrapper {
  display: flex;
  gap: var(--space-5x);
  margin-top: var(--space-2x);
}

.download {
  display: block;
  border: 0;
  font-size: 0;
  max-width: 150px;
  appearance: none;
  transition: var(--duration);
}

.download:hover {
  opacity: 0.8;
}

.download:active {
  opacity: 0.6;
}

.download img {
  width: 100%;
  height: auto;
}

.app2DCode {
}

@media (max-width: 1024px) {
  .root {
  }
  .downloadContainer {
    height: auto;
    padding: var(--space-6x);
    flex-direction: column;
    gap: 0;
  }
  .header {
    flex-direction: column-reverse;
    gap: var(--space-4x);
  }
  .figure {
    position: static;
    transform: none;
    margin-top: var(--space-5x);
  }
  .main {
    width: 100%;
  }
  .headingImg {
    height: 36px;
  }
  .downloadWrapper {
    display: none;
  }
  .appIconImg {
    width: 48px;
    height: 48px;
  }
  .action {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--space-6x);
  }
}
