.container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: var(--color-white);
  overflow: hidden;
}

.container.init {
  transform: translateY(100%);
}

.container.opened {
  transform: translateY(0);
  transition: transform 0.2s;
}

.height--fullScreen {
  height: calc(100% - var(--space-4x));
}

.height--auto {
  height: auto;
  max-height: 100%;
  overflow-y: scroll;
}

.container--mobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top-left-radius: var(--rounded-md);
  border-top-right-radius: var(--rounded-md);
  padding-bottom: var(--space-2x);
}

.container--desktop,
.container--desktop-lg {
  width: 500px;
  height: auto;
  max-height: 90vh;
  border-radius: var(--rounded-md);
}

.container--desktop-lg {
  width: 744px;
}

.container--desktop .body {
  max-height: 700px;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--border-gray);
}

/* .header--desktop {
} */
/* swipe function is difficult to implement. let me comment out as of now.
.header--mobile:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  height: 4px;
  width: 48px;
  transform: translate(-50%, -100%);
  background-color: var(--color-gray-10);
  border-radius: 4px;
} */

.body {
  flex-basis: 100%;
  overflow: auto;
}

.close {
  appearance: none;
  background: none;
  border: 0;
  position: absolute;
  top: 50%;
  left: var(--space-4x);
  transform: translateY(-50%);
  cursor: pointer;
}

.action {
  flex-shrink: 0;
  padding: var(--space-4x) var(--space-6x);
  border-top: 1px solid var(--border-gray);
}

@media screen and (min-width: 768px) {
  .container.init {
    opacity: 0;
    transform: scale(0.9);
  }

  .container.opened {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.2s;
  }
}
