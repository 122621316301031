.root {
  padding-top: var(--space);
  padding-bottom: var(--space);
}

.lastChild {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .root {
    padding-bottom: var(--space-2x);
  }
}
