.root {
  color: inherit;
}
.inner {
  width: 100%;
  overflow: hidden;
}
.thumbnail {
  position: relative;
  width: 100%;
  height: 142px;
  flex-shrink: 0;
}
.variant__toursWithQueryForMag .thumbnail {
  height: 160px;
}
.thumbnailInner {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: var(--rounded);
}
.variant__toursWithQueryForMag .thumbnailInner {
  border-radius: var(--rounded) var(--rounded) 0 0;
}
.thumbnail .mainImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.labels {
  position: absolute;
  top: 0;
  padding: var(--space-2x);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.wishlistButtonWrapper {
  position: absolute;
  margin: var(--space);
  top: 0;
  right: 0;
  z-index: var(--z-index-float);
}
.stayInfo {
  position: absolute;
  bottom: var(--space-2x);
  padding-left: var(--space-2x);
  display: flex;
  width: 100%;
  font-size: var(--fontSize-sm);
  font-weight: bold;
  color: var(--color-white);
}
.stayInfo > :first-child {
  margin-right: var(--space-2x);
}
.stayInfo > :nth-child(2) {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.stayInfo > :nth-child(3) span {
  margin-right: var(--space);
}
.thumbnail .imageCover {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: var(--rounded);
}
.title {
  margin-top: var(--space-2x);
  height: calc(var(--lineHeight-lg) * 2);
  line-height: var(--lineHeight-lg);
  font-size: var(--fontSize-base);
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.items {
  margin-top: var(--space);
}
.items > li + li {
  margin-top: var(--space);
}
.item {
  display: flex;
  align-items: center;
  word-break: keep-all;
}
.itemBody {
  width: 100%;
}
.item span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hotel {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  gap: var(--space-2x);
}
.hotelRating {
  flex-shrink: 0;
}
.hotelNameWrap {
  display: flex;
  align-items: center;
  gap: var(--space);
  overflow: hidden;
}
.hotelName {
  flex: 1 0 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.footer {
  margin-top: var(--space);
}
.price {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.price > dt {
  color: var(--color-gray-50);
  font-weight: bold;
  font-size: var(--fontSize-xs);
  margin-right: var(--space-2x);
}
.price > dd {
  font-size: var(--fontSize-xl);
  line-height: var(--lineHeight-lg);
  font-weight: bold;
}
.point {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: var(--space);
}
.point > span {
  font-size: var(--fontSize-sm);
  line-height: 1.2;
}
.point > span > span {
  font-weight: bold;
  margin-right: var(--space);
  margin-left: var(--space);
}

@media (max-width: 1024px) {
  .thumbnail:not(
      .variant__recommendedTourCard .thumbnail,
      .variant__toursWithQueryForMag .thumbnail
    ) {
    height: 110px;
  }
}

.flightInfoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--space-2x);
}

.airlineSection {
  display: flex;
  align-items: center;
  gap: var(--space);
  min-width: 0;
  flex: 0 1 auto;
}

.airlineImage {
  width: 20px;
  height: 20px;
  object-fit: contain;
  flex-shrink: 0;
}

.airlineName {
  min-width: 0;
}

.flightDetailsSection {
  display: flex;
  align-items: center;
  gap: var(--space-2x);
  flex-shrink: 0;
}

.flightTime {
  width: 28px;
  padding-left: var(--space);
  padding-right: var(--space);
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flightTimeWrapper {
  white-space: nowrap;
}
