.root {
  display: flex;
  border: 2px solid var(--border-gray);
  border-radius: var(--rounded);
  width: 100%;
  color: inherit;
}
.root:not(:last-child) {
  margin-bottom: var(--space-6x);
}
.inner {
  display: flex;
  width: 100%;
  height: 260px;
  padding: var(--space-4x) var(--space-6x) var(--space-6x) var(--space-4x);
}
.thumbnail {
  position: relative;
  width: 300px;
  flex-shrink: 0;
  background-color: var(--color-gray-10);
}
.thumbnailInner {
  position: relative;
  height: 0;
  padding-top: 73.33333%;
  overflow: hidden;
  border-radius: var(--rounded);
}
.thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upperLeft {
  position: absolute;
  padding: var(--space-4x);
  z-index: 1;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.lowerRight {
  position: absolute;
  padding: var(--space-4x);
  z-index: 1;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  bottom: 0;
  justify-content: flex-end;
}
.detail {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: calc(100% - 300px);
  padding-left: var(--space-6x);
}
.title {
  font-size: var(--fontSize-lg);
  line-height: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.main {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-2x);
  flex-direction: column;
  height: 100%;
}
.leftBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.items > li:not(:first-child) {
  margin-top: var(--space);
}
.item {
  display: flex;
  align-items: center;
}
.itemIcon {
  flex-shrink: 0;
  display: flex;
}
.itemBody {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
}
.itemIcon + .itemBody {
  padding-left: var(--space-2x);
  width: calc(100% - 16px);
}
.hotel {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
}
.hotelRating {
  display: flex;
  flex-shrink: 0;
}
.hotelRating + .hotelName {
  padding-left: var(--space-2x);
}
.rating {
  display: flex;
  align-items: center;
}
.overall {
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-base);
  font-weight: bold;
}
.totalCount {
  margin-left: var(--space-2x);
}

.refundable {
  display: flex;
  align-items: center;
  margin-top: var(--space);
}
.refundable .refundableBody {
  color: var(--color-primary);
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
  font-weight: bold;
}
.refundableIcon + .refundableBody {
  padding-left: var(--space);
}

.wishlistButtonWrapper {
  position: absolute;
  margin: var(--space-2x);
  top: 0;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .root {
    border: 0;
    border-radius: 0;
    padding-top: var(--space-4x);
    padding-bottom: var(--space-4x);
  }
  .root:not(:last-child) {
    margin-bottom: 0;
    border-bottom: 1px solid var(--color-gray-20);
  }
  .inner {
    flex-direction: column;
    padding: 0;
    height: auto;
  }
  .thumbnail {
    width: auto;
  }
  .thumbnailInner {
    padding-top: 50%;
  }
  .detail {
    width: auto;
    padding-left: 0;
    margin-top: var(--space-2x);
  }
  .main {
    flex-direction: row;
  }
  .title {
    font-size: var(--fontSize-base);
    line-height: var(--lineHeight-lg);
  }
  .action {
    display: none;
  }
}
