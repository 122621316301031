/* Figma https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=4024%3A14267 */

.root {
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.root--disabled {
  cursor: default;
}

.checkbox {
  appearance: none;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: var(--rounded-xs);
  margin-right: var(--space-2x);
  cursor: pointer;
}

.checkbox:checked {
  background-image: url("./assets/check.svg");
  background-size: 14px 10px;
  background-position: 50%;
  background-repeat: no-repeat;
}

.root > * {
  line-height: var(--space-5x);
}

/* default */
.default {
  border-color: var(--color-disabled);
  background-color: var(--color-white);
}

.default:checked {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

/* disabled */
.disabled {
  border: 0;
  background-color: var(--color-disabled-light);
  cursor: not-allowed;
}

.disabled:checked {
  border-color: var(--color-disabled-light);
  background-color: var(--color-disabled-light);
}

/* error */
.error {
  border: 2px solid var(--color-danger);
  background-color: var(--color-white);
}

.error:checked {
  border-color: var(--color-danger);
  background-color: var(--color-danger);
}
