.root {
  display: flex;
}
.root + .root {
  margin-top: var(--space-8x);
  padding-top: var(--space-8x);
  border-top: 1px solid var(--border-gray);
}
.figure {
  max-width: 300px;
  width: 100%;
  flex-shrink: 0;
}
.rows {
  flex-basis: 100%;
  padding-left: var(--space-6x);
  padding-top: var(--space-4x);
}
.rows > li + li {
  margin-top: var(--space-4x);
}

@media screen and (max-width: 767px) {
  .root {
    display: block;
  }
  .root + .root {
    margin-top: var(--space-4x);
    padding-top: var(--space-4x);
    border-top: 1px solid var(--border-gray);
  }
  .figure {
    max-width: 100%;
  }
  .rows {
    padding-left: 0;
  }
}