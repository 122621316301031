.scale {
  transform: translateX(-12.5%) translateY(-12.5%) scale(0.75);
}
.root {
  position: relative;
  width: 240px;
  height: 340px;
  border-radius: 12px;
  background: linear-gradient(149deg, #f2faf6 46.04%, #e7f4f6 97.9%);
  box-shadow:
    0px 0px 3.2px 0px rgba(0, 0, 0, 0.04),
    0px 3.2px 6.4px 0px rgba(0, 0, 0, 0.06);
}

.root::before {
  content: "";
  position: absolute;
  top: 0px;
  left: calc(50% - (12.5px / 2));
  width: 25px;
  height: 12.5px;
  background-color: white;
  border-radius: 0 0 25px 25px;
}

.image {
  border-radius: 12px 12px 0 0;
}

.content {
  padding: 0 var(--space-5x) var(--space-5x) var(--space-5x);
  height: 180px;
}

.airport {
  width: 74px;
}

.title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: bold;
  line-height: 1.4;
  height: 70px;
}

.logo {
  position: absolute;
  width: 100%;
  bottom: 19.5px;
  display: flex;
  justify-content: center;
}
