.center {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.globalNavAndSearchButton {
  display: flex;
  flex: 1;
  align-items: center;
  transition: transform 250ms ease, opacity 100ms ease, visibility 0ms 50ms;
}

.globalNavAndSearchButton button {
  margin-left: var(--space-8x);
}

.searchBoxVisible .globalNavAndSearchButton {
  opacity: 0;
  pointer-events: none;
}

.headerBackground::after {
  background-color: var(--bg-white);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: transform 250ms ease;
  transform-origin: 50% 0%;
}

.searchBoxVisible::after {
  transform: scaleY(2.86);
}

.searchBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -166px;
  top: 52px;
  z-index: 1;
  transform-origin: 50% 0;
  transition: transform 250ms ease, opacity 200ms ease 100ms,
    visibility 0ms 200ms;
}

.searchBoxHidden .searchBox {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  will-change: transform, opacity;
  transform: scale(0.3) translateY(-58px);
}

.searchBoxOverlay {
  background-color: rgb(0 0 0 / 25%);
  inset: 0;
  position: fixed;
  height: 100vh;
}

.searchBoxInHeader {
  border: 3px solid var(--border-gray-light);
  box-shadow: unset;
}