.root {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-80);
  overflow: hidden;
  z-index: var(--z-index-modal);
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--space-5x) var(--space-5x);
  margin: var(--space-2x) var(--space-5x);
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 80%;
  max-width: 1032px;
}

.image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.leftButton,
.rightButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border-gray-dark);
  background: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.leftButton {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: var(--space-10x);
}

.rightButton {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: var(--space-10x);
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--space-6x) var(--space-10x);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  font-size: var(--fontSize-sm);
}

.captionWrapper {
  align-self: baseline;
}

.countWrapper {
  min-width: 70px;
  text-align: end;
  align-self: flex-end;
}

.count {
  background-color: rgba(0, 0, 0, 0.6);
  padding: var(--space) var(--space-2x);
  border-radius: 5px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .root {
    display: block;
  }

  .root.init {
    transform: translateY(100%);
  }

  .root.opened {
    transform: translateY(0);
    transition: transform 0.1s;
  }

  .close {
    position: initial;
    padding: var(--space-3x);
    margin: 0 0 0 var(--space-2x);
  }
  .imageWrapper {
    display: block;
    margin: var(--space-10x) 0;
    height: calc(100% - 240px);
  }

  .footer {
    display: block;
    position: initial;
    padding: 0 var(--space-5x);
    margin-bottom: var(--space-4x);
  }

  .caption {
    height: 45px;
    overflow-y: scroll;
  }

  .countWrapper {
    margin-top: var(--space-4x);
    text-align: center;
  }

  .spClickableArea {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .leftArea {
    height: 100%;
    width: 50%;
  }

  .leftArea:active {
    background-color: rgba(256, 256, 256, 0.01);
  }

  .rightArea {
    height: 100%;
    width: 50%;
  }
  .rightArea:active {
    background-color: rgba(256, 256, 256, 0.01);
  }
}
