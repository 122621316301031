.priceCardItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
  margin-bottom: var(--space-2x);
}

.priceCardItem--gray > span:first-child {
  color: var(--color-gray-50);
  font-weight: bold;
}

.priceCardItem--red > span:first-child {
  color: var(--color-red-30);
  font-weight: bold;
}
.priceCardItem--red > span:nth-child(2) {
  color: var(--color-red-30);
}

.priceCardItem--bold > span:first-child {
  font-weight: bold;
}
.priceCardItem--bold > span:nth-child(2) {
  font-size: var(--fontSize-2xl);
  line-height: var(--lineHeight-3xl);
  font-weight: bold;
}
