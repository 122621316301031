.root {
  display: block;
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
  white-space: pre-line;
}

.root :global(ul) {
  display: inline-table;
  margin-left: var(--space-2x);
}

.root :global(li) {
  list-style: disc;
  list-style-position: outside;
  white-space: pre-line;
  word-break: break-all;
  margin-left: var(--space-2x);
  margin-bottom: var(--space-2x);
}

.root :global(li ul) {
  display: inline-table;
  margin-top: var(--space-2x);
  width: 100%;
}

.root :global(a) {
  color: var(--color-link);
}

.root :global(h3) {
  font-weight: bold;
  margin-top: var(--space-4x);
  margin-bottom: var(--space-2x);
}
