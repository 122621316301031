.root {
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--rounded-md);
  max-width: 90vw;
  width: 504px;
  line-height: 1.5;
  overflow: hidden;
  font-size: var(--fontSize-lg);
}

.init {
  opacity: 0;
  transform: scale(0.9);
}

.opened {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.2s;
}

.close {
  position: absolute;
  top: var(--space-3x);
  right: var(--space-4x);
}

.header {
  height: 48px;
  padding: var(--space-3x);
}

.body {
  padding: var(--space-2x) var(--space-5x) var(--space-4x);
  white-space: pre-wrap;
}

.body a {
  color: var(--color-blue-80);
  text-decoration: underline;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-4x) var(--space-8x);
  border-top: 1px solid var(--border-gray);
}
.actions > li {
  width: 100%;
}
.actions > li + li {
  margin-left: var(--space-4x);
}
