.spModalRoot {
  background-color: #f2faf6;
}

.spRoot {
  padding: 0 var(--space-6x) var(--space-8x);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pcRoot {
  background-color: #f2faf6;
  display: flex;
  justify-content: center;
}

.confettiBall {
  position: relative;
  width: 375px;
  height: 323px;
  min-height: 323px;
}

.textImage {
  position: relative;
  width: 224px;
  height: 84px;
  min-height: 84px;
}

.buttonWrapper {
  width: 100%;
  margin-top: var(--space-6x);
}

.checkbox {
  /** place bottom */
  height: 100%;
  display: flex;
  align-items: end;
  min-height: 30px;
}

.pcCheckbox {
  display: flex;
  justify-content: center;
  padding-top: var(--space-10x);
}

@media (max-height: 600px) {
  .textImage {
    min-height: 60px;
  }
}
