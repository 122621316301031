.root {
  width: 311px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  cursor: pointer;
  animation: toast 0.32s forwards; /** var(--duration-lazy)が読み込めないのでハードコード */
  z-index: var(--z-index-toast);
}

@keyframes toast {
  0% {
    bottom: 0;
    transform: translate(-50%) scale(1);
    opacity: 0;
  }
  100% {
    bottom: calc(56px + 24px); /** only mobile and tablet */
    transform: translate(-50%) scale(1);
    opacity: 1;
  }
}

.inner {
  font-weight: bold;
  border-radius: var(--rounded);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow-dark);
  margin: 0 var(--space-4x);
}

.left {
  padding: var(--space-4x);
  padding-right: var(--space-2x);
  display: flex;
  align-items: center;
}

.right {
  padding: var(--space-4x);
  padding-left: var(--space-2x);
  font-weight: bold;
  font-size: var(--fontSize-base);
  color: var(--color-blue-70);
  width: 70px;
}

.variant--success {
  background-color: var(--color-green-5);
  border: 1px solid var(--color-green-10);
}

.variant--error {
  background-color: var(--color-danger);
}

@media screen and (min-width: 768px) {
  .root {
    width: 560px;
  }
}

@media screen and (min-width: 1025px) {
  @keyframes toast {
    0% {
      bottom: 0;
      transform: translate(-50%) scale(1);
      opacity: 0;
    }
    100% {
      bottom: 40px; /** only pc */
      transform: translate(-50%) scale(1);
      opacity: 1;
    }
  }
}
