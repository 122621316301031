.root {
  text-align: center;
}

.loadingText {
  position: relative;
  margin-top: -40px;
}

.size--base img {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 767px) {
  .size--base img {
    width: 150px;
    height: 150px;
  }
}
