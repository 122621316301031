.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  font-size: 0;
}

.clickable {
  cursor: pointer;
}

.root svg {
  width: 100%;
  height: 100%;
}

.size--xs {
  width: 12px;
  height: 12px;
}
.size--sm {
  width: 16px;
  height: 16px;
}
.size--base {
  width: 20px;
  height: 20px;
}
.size--md {
  width: 24px;
  height: 24px;
}
.size--lg {
  width: 32px;
  height: 32px;
}
.size--xl {
  width: 48px;
  height: 48px;
}
.size--2xl {
  width: 56px;
  height: 56px;
}
