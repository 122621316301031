.title {
  font-size: var(--fontSize-4xl);
  line-height: var(--lineHeight-5xl);
  padding: var(--space-3x) 0;
}
.description {
  margin-bottom: var(--space-6x);
}
.descriptionTitle {
  font-size: var(--fontSize-2xl);
  line-height: var(--lineHeight-3xl);
  padding: var(--space-2x) 0;
  font-weight: bold;
}
.descriptionContent {
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-lg);
}
.descriptionContent p {
  margin-bottom: var(--space);
}
.descriptionContent ul {
  padding-left: var(--space-4x);
}
.descriptionContent li {
  list-style: disc;
  margin-bottom: var(--space);
}
.descriptionsCompact {
  position: relative;
  overflow: hidden;
  max-height: 208px;
}
.blur {
  position: absolute;
  z-index: var(--z-index-float);
  width: 100%;
  height: 50%;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    var(--color-transparent),
    var(--bg-white)
  );
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-6x);
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: var(--fontSize-lg);
    line-height: var(--lineHeight-xl);
  }

  .descriptionTitle {
    font-size: var(--fontSize-base);
    line-height: var(--lineHeight-lg);
  }

  .descriptionContent {
    font-size: var(--fontSize-sm);
    line-height: var(--lineHeight-base);
  }
}
