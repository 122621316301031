.root {
  display: inline-flex;
  align-items: center;
}

.size--base .icon {
  width: 16px;
  height: 16px;
}
.size--lg .icon {
  width: 24px;
  height: 24px;
}

.icon {
  background: url('./assets/rating_star.svg') no-repeat center;
  background-size: cover;
}

.icon + .icon {
  margin-left: 1px;
}

.icon--hidden {
  visibility: hidden;
}
