.link {
  cursor: pointer;
}

.root {
  position: relative;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
  overflow: hidden;
  height: 364px;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.title {
  color: var(--color-white);
  font-size: 48px;
  margin: 0;
}

.sp {
  display: none;
}

@media screen and (max-width: 1024px) {
  .root {
    border-radius: 0;
  }
  .inner {
    height: 100%;
    margin-top: 0px;
  }
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .root {
    height: 260px;
  }
}
