.root {
  padding: var(--space-8x);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legends {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-bottom: var(--space-6x);
}

.calendarActions {
  display: flex;
  margin-top: var(--space-4x);
}

.calendarActions > li + li {
  margin-left: var(--space-4x);
  width: 100%;
}

.clearButton {
  min-width: 110px;
}

.calendarFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--space-4x);
}

.clearButton {
  border: 0;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-default);
  font-weight: bold;
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-base);
  text-underline-offset: 2px;
  float: right;
  width: auto;
}
.clearButton:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1024px) {
  .root {
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
  .legends {
    padding: var(--space-4x);
  }
}
