.header {
  position: sticky;
  top: 78px;
  z-index: calc(var(--z-index-modal) + 1);
  background: var(--color-white);
}

.header.webview {
  top: 0px;
}

@media screen and (max-width: 1024px) {
  .header {
    top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .root.init {
    transform: translateX(100%);
  }

  .root.opened {
    transform: translateX(0);
    transition: transform 0.1s;
  }
}
