.nav {
  background-color: var(--color-white);
  margin-bottom: -3px;
}

.nav li {
  flex-grow: 1;
}

.nav .removeUnderline > a::before {
  content: none;
}

.center {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.right {
  display: flex;
  gap: var(--space-4x);
  align-items: center;
  margin-left: var(--space-4x);
}

.menuOpenButton {
  display: none;
}

.myPageButton {
  display: grid;
  place-content: center;
  width: 48px;
  height: 40px;
  padding-bottom: 2px;
}

.myPageButton > i {
  position: relative;
}

.myPageButton.hasBadge > i::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--color-danger);
  border-radius: 100%;
  top: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .right {
    gap: 0;
  }

  .menuOpenButton {
    display: flex;
    align-items: center;
  }
}
