.root {
  margin-bottom: var(--space-6x);
}

.label {
  display: flex;
  min-height: var(--space-6x);
  align-items: center;
  gap: 0 var(--space-2x);
}

.description {
  margin-top: var(--space-3x);
}

.body {
  margin: var(--space-2x) 0;
}
