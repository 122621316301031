.root {
  display: inline-flex;
  font-size: 0;
}
.root svg {
  width: auto;
  height: 100%;
  vertical-align: bottom;
}
.variant--vertical {
  height: 120px;
}
.variant--horizontal {
  height: 33px;
}
.variant--icon {
  height: 24px;
}
.color--primary > svg path {
  fill: var(--color-primary);
}
.color--white > svg path {
  fill: var(--color-white);
}

@media screen and (max-width: 767px) {
  .variant--horizontal {
    height: 30px;
  }
}
