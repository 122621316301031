.root {
  padding: var(--space-4x);
  display: block;
}

.variant--default {
  border-bottom: 1px solid var(--color-gray-10);
}

.variant--card {
  padding: var(--space-6x);
  border: 1px solid var(--color-gray-30);
  border-radius: var(--rounded);
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
  background-color: var(--color-gray-5);
}

.variant--card--alert {
  margin: var(--space-4x) calc(var(--space-6x) * -1) calc(var(--space-6x) * -1);
  border-radius: 0 0 var(--rounded) var(--rounded);
  overflow: hidden;
}

.subText {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-base);
  margin-top: var(--space-3x);
  color: var(--color-gray-80);
}
