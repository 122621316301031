.root {
  height: 72px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.item {
  background-color: var(--color-gray-10);
  border-radius: 2px 2px 0 0;
  margin-right: 1px;
  margin-left: 1px;
  flex-grow: 1;
}

.item.active {
  background-color: var(--color-primary);
}
