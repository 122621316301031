.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  flex-grow: 1;
  padding-top: var(--space-6x);
  padding-bottom: var(--space-10x);
}

@media screen and (max-width: 767px) {
  .body {
    padding-top: var(--space-4x);
    margin-top: 0;
  }
}
