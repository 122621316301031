.root {
  margin-bottom: 25px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-8x);
}

.title {
  line-height: 48px;
  font-size: var(--fontSize-xl);
  font-weight: bold;
}

.image {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: var(--rounded);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .grid {
    gap: var(--space-2x);
  }

  .title {
    line-height: 40px;
    font-size: var(--fontSize-base);
  }

  .image {
    border-radius: var(--rounded-xs);
  }
}
