.root {
  background-color: var(--color-white);
  padding-top: var(--space-10x);
  padding-bottom: var(--space-8x);
}
.inner {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.header {
  padding-left: var(--space-16x);
  padding-right: var(--space-4x);
}
.headingImg {
  height: 18px;
  width: auto;
}
.description {
  margin-top: var(--space-3x);
}
.description > span {
  line-height: 1.8;
}
.main {
}
.link {
  display: block;
  border: 0;
  font-size: 0;
  width: 48px;
  appearance: none;
  transition: var(--duration);
}
.link:hover {
  opacity: 0.8;
}
.link:active {
  opacity: 0.6;
}

.link img {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .root {
    padding-top: var(--space-6x);
    padding-bottom: var(--space-6x);
  }
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
  .description {
    margin-top: var(--space-4x);
  }
  .main {
    margin-top: var(--space-4x);
  }
  .link {
    width: 40px;
  }
}
