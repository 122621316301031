.root {
  color: inherit;
}
.inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--space-2x) var(--space-4x) var(--space-2x) var(--space-2x);
  border-radius: var(--rounded);
  border: 1px solid var(--color-gray-20);
}
.thumbnail {
  width: 64px;
  height: 64px;
}
.thumbnail img {
  border-radius: var(--rounded);
}
.title {
  line-height: var(--lineHeight-2xl);
  font-size: var(--fontSize-base);
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: var(--space-4x);
}
.main {
  background-color: var(--color-white);
}

@media screen and (max-width: 767px) {
  .inner {
    padding: 6px var(--space-4x) 6px 6px;
  }
  .thumbnail {
    width: 36px;
    height: 36px;
  }
  .title {
    font-size: var(--fontSize-sm);
    line-height: 20px;
    margin-left: var(--space-2x);
  }
}
