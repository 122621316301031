.slider {
  margin-top: var(--space-8x);
  overflow: auto;
}
.sliderList {
  display: flex;
}
.sliderList > li + li {
  margin-left: var(--space-4x);
}

.shape--square {
  width: 216px;
  height: 216px;
}

.shape--oblong {
  width: 310px;
  height: 216px;
}

.sliderItem {
  position: relative;
  overflow: hidden;
  border-radius: var(--rounded);
}

.sliderItem img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .slider {
    margin-top: var(--space-4x);
  }
}

@media screen and (max-width: 767px) {
  .slider {
    margin-left: calc(var(--space-6x) * -1);
    margin-right: calc(var(--space-6x) * -1);
  }
  .sliderList > li:first-child {
    padding-left: var(--space-6x);
  }
  .sliderList > li:last-child {
    padding-right: var(--space-6x);
  }
  .sliderItem {
    position: relative;
    overflow: hidden;
    border-radius: var(--rounded);
  }
}
