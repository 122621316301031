.root {
  display: inline-flex;
  align-items: center;
  color: var(--color-white);
  border-radius: var(--rounded-xs);
  font-weight: bold;
}

.root--sm {
  height: 24px;
  font-size: var(--fontSize-sm);
  padding: var(--space-2) var(--space-2x);
}

.root--base {
  height: 32px;
  font-size: var(--fontSize-base);
  padding: var(--space) var(--space-4x);
}

.root:not(:last-child) {
  margin-right: var(--space-2x);
}

.themeIcon {
  height: 16px;
  width: 16px;
}

.root--sm .themeIcon {
  margin-right: var(--space);
}
.root--base .themeIcon {
  margin-right: var(--space-2);
}

.root--sm .label {
  line-height: 20px;
}

.root--base .label {
  line-height: 24px;
}
