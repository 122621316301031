/* Figma https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=4024%3A14190 */
.root {
  display: flex;
  justify-items: center;
  width: 100%;
  position: relative;
}

.icon {
  pointer-events: none;
  position: absolute;
  right: var(--space-2x);
  line-height: var(--space-10x);
}

.select {
  border: 2px solid var(--color-disabled);
  border-radius: var(--rounded-xs);
  width: 100%;
  height: var(--space-12x);
  padding: 0 var(--space-3x);
  font-size: var(--fontSize-base);
  appearance: none;
  color: var(--color-black);
  background: var(--color-white);
}

.select:focus-visible {
  outline: 2px solid var(--color-focus);
}

.select:hover {
  outline: none;
  border: 2px solid var(--color-blue-dark);
}

.disabled {
  background: var(--color-disabled-light);
  color: var(--color-disabled-dark);
  border-color: var(--color-disabled-light);
}

.disabled:hover {
  border-color: var(--color-disabled-light);
  cursor: not-allowed;
}

.error {
  border-color: var(--color-danger);
}
