.root {
  display: flex;
  align-items: center;
  height: var(--header-height-mobile);
  box-shadow: var(--shadow);
}

.logo {
  margin-top: var(--space);
}

@media screen and (min-width: 640px) {
  .root {
    height: var(--header-height-pc);
  }
}
