.deleteButton {
  text-align: center;
  margin-top: var(--space-6x);
  width: 335px;
}

@media (max-width: 767px) {
  .deleteButton {
    width: 100%;
  }
}
