.more {
  width: 32%;
  margin-top: var(--space-10x);
  margin: var(--space-10x) auto 0;
}

@media screen and (max-width: 1024px) {
  .carousel {
    margin-inline: calc(var(--space-4x) * -1);
  }
  .carousel li:first-child {
    padding-left: var(--space-4x);
  }
  .carousel li:last-child {
    padding-right: var(--space-4x);
  }

  .more {
    width: 100%;
    margin-top: var(--space-6x);
    padding-left: var(--space-2x);
    padding-right: var(--space-2x);
  }
}
