.root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  transform: scale(1.5);
}

@media screen and (max-width: 1024px) {
  .inner {
    margin: 50% 0;
  }
}
