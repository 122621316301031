.root {
  display: block;
}

/* Horizontal */
.horizontal--1 {
  width: var(--space-1);
}

.horizontal--2 {
  width: var(--space-2);
}

.horizontal--base {
  width: var(--space);
}

.horizontal--2x {
  width: var(--space-2x);
}

.horizontal--3x {
  width: var(--space-3x);
}

.horizontal--4x {
  width: var(--space-4x);
}

.horizontal--5x {
  width: var(--space-5x);
}

.horizontal--6x {
  width: var(--space-6x);
}

.horizontal--8x {
  width: var(--space-8x);
}

.horizontal--10x {
  width: var(--space-10x);
}

.horizontal--12x {
  width: var(--space-12x);
}

.horizontal--14x {
  width: var(--space-14x);
}

.horizontal--16x {
  width: var(--space-16x);
}

.horizontal--20x {
  width: var(--space-20x);
}

.horizontal--30x {
  width: var(--space-30x);
}

/* Vertical */
.vertical--0 {
  height: 0px;
}

.vertical--1 {
  height: var(--space-1);
}

.vertical--2 {
  height: var(--space-2);
}

.vertical--base {
  height: var(--space);
}

.vertical--2x {
  height: var(--space-2x);
}

.vertical--3x {
  height: var(--space-3x);
}

.vertical--4x {
  height: var(--space-4x);
}

.vertical--5x {
  height: var(--space-5x);
}

.vertical--6x {
  height: var(--space-6x);
}

.vertical--8x {
  height: var(--space-8x);
}

.vertical--10x {
  height: var(--space-10x);
}

.vertical--12x {
  height: var(--space-12x);
}

.vertical--14x {
  height: var(--space-14x);
}

.vertical--16x {
  height: var(--space-16x);
}

.vertical--20x {
  height: var(--space-20x);
}

.vertical--30x {
  height: var(--space-30x);
}
