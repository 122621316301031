.root {
  display: grid;
  grid-gap: var(--space-4x);
  grid-template-columns: 1fr 1fr;
}

.root li {
  display: flex;
  align-items: center;
  min-height: 120px;
  border-bottom: 1px var(--border-gray) solid;
}

.category {
  width: 20%;
}

@media (max-width: 767px) {
  .root {
    display: block;
  }
  .root li {
    min-height: 90px;
    padding: var(--space-5x) 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .category {
    width: auto;
  }
}
