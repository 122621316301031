.navList {
  display: flex;
}

.navList li {
  text-align: center;
}

.link {
  min-width: 93px;
  height: 40px;
  padding: 6px var(--space-2x) 12px;
  font-weight: bold;
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-lg);
  align-items: center;
  color: var(--color-gray-80);
  line-height: var(--lineHeight-lg);
  display: inline-block;
}

.active .link {
  position: relative;
}

.active .link:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 6px; /*線の上下位置*/
  display: inline-block;
  width: 56px; /*線の長さ*/
  height: 4px; /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); /*位置調整*/
  background-color: var(--color-green-40); /*線の色*/
  border-radius: 2px; /*線の丸み*/
}

.link > i {
  margin-right: var(--space);
  margin-top: -2px;
}
