.root {
  position: relative;
  width: 150px;
  height: 50px;
}

.download {
  display: block;
  border: 0;
  font-size: 0;
  appearance: none;
  transition: var(--duration);
}

.download:hover {
  opacity: 0.8;
}

.download:active {
  opacity: 0.6;
}

.download img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .root {
    width: 125px;
  }
}
