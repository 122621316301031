.root {
  display: flex;
  align-items: center;
  appearance: none;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  text-align: left;
  outline: none;
}
.root:hover {
  opacity: 0.7;
  cursor: pointer;
}
.icon {
  flex-shrink: 0;
}
.definition {
  flex-grow: 1;
  width: calc(100% - 24px);
  padding-left: var(--space-2x);
  color: var(--color-default);
}
.definition > dt,
.definition > dd {
  font-weight: bold;
}
.definition > dt {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
  height: var(--lineHeight-sm);
}
.definition > dd {
  color: var(--color-default);
  font-size: var(--fontSize-base);
  line-height: var(--lineHeight-base);
  height: var(--lineHeight-base);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.definition > dt + dd {
  margin-top: var(--space);
}

@media screen and (max-width: 1024px) {
  .root {
    height: 56px;
  }
  .definition {
    padding-left: var(--space-4x);
  }
  .definition > dt {
    display: none;
  }
  .definition > dt + dd {
    margin-top: 0;
  }
}
