.root {
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  white-space: nowrap;
  outline: none;
}
.text {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
  font-weight: bold;
  color: var(--color-gray-80);
}
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-6x);
}
