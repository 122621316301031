.footer {
  display: none;
}

@media screen and (max-width: 1024px) {
  .footer {
    background-color: var(--color-white);
    padding: var(--space-4x) var(--space-6x) var(--space-10x);
    display: block;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: calc(var(--z-index-follow) + 1);
    border-top: 1px solid var(--border-gray-light);
    transition:
      -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
      -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
      transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
      visibility 0.2s ease 0s;
  }

  .footerHidden {
    transform: translateY(100%);
    visibility: hidden;
  }
}
