.root {
  box-sizing: border-box;
  width: 100%;
}

.align--centered {
  margin-left: auto;
  margin-right: auto;
}

.align--left {
  display: block;
}

.align--right {
  float: right;
}

.spacing--0 {
  padding: 0;
}

.spacing--base {
  padding: 0 var(--space);
}

.spacing--2x {
  padding: 0 var(--space-2x);
}

.spacing--3x {
  padding: 0 var(--space-3x);
}

.spacing--4x {
  padding: 0 var(--space-4x);
}

.spacing--5x {
  padding: 0 var(--space-5x);
}

.spacing--8x {
  padding: 0 var(--space-8x);
}

.spacing--16x {
  padding: 0 var(--space-16x);
}

.size--base {
  max-width: 1064px;
}

.size--wide {
  max-width: 1240px;
}

.size--1\/3 {
  max-width: 375px;
}

.size--1\/2 {
  max-width: 532px;
}

.size--2\/3 {
  max-width: 708px;
}

@media screen and (max-width: 767px) {
  .root {
    max-width: 100%;
  }
}
