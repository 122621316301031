.root {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .img {
    margin-right: var(--space-8x);
  }
}
