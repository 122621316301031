.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-top: var(--space-4x);
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  margin-left: var(--space-4x);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.creditCardRoot {
  display: flex;
  align-items: center;
}

.creditCardIcon {
  margin-right: var(--space-4x);
}

.creditCardLabel {
  margin-bottom: var(--space);
}
