.root {
  display: flex;
  flex-direction: column-reverse;
  color: inherit;
  text-decoration: none;
}

.figure {
  position: relative;
  height: 0;
  padding-top: 100%;
  border-radius: var(--rounded);
  overflow: hidden;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
  vertical-align: middle;
  backface-visibility: hidden;
}

.disabledImg {
  filter: brightness(0.5);
}

.tag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-basis: 100%;
  margin-top: var(--space-5x);
}

.titleBody {
  flex-basis: 100%;
}

.titleIcon {
  flex-shrink: 0;
  padding-top: 3px;
}

@media screen and (max-width: 767px) {
  .title {
    margin-top: var(--space-2x);
  }
  .titleIcon {
    padding-top: 0;
  }
}
