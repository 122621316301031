.root {
  margin-bottom: var(--space-3x);
}

.help {
  margin-top: var(--space-3x);
}

.body {
  margin-top: var(--space-4x);
}
