.description {
  margin-bottom: var(--space-6x);
}
.descriptionTitle {
  font-size: var(--fontSize-lg);
  line-height: var(--lineHeight-lg);
  font-weight: bold;
}
.descriptionContent {
  font-size: var(--fontSize-sm);
  line-height: var(--lineHeight-sm);
  margin-top: var(--space-2x);
}

.descriptionContent i {
  margin-right: var(--space-4x);
}

.onSitePayment i {
  margin-bottom: var(--space-2x);
}
