.root {
  display: inline-block;
  vertical-align: middle;
}

.root > img {
  object-fit: contain;
}

.size--md {
  height: 24px;
}
.size--lg {
  height: 32px;
}
.size--xl {
  height: 48px;
}
