.root {
  display: flex;
  align-items: flex-end;
}

.root input {
  width: 80px;
}

.label {
  height: 100%;
  font-size: var(--fontSize-base);
  padding-left: var(--space-2x);
  padding-right: var(--space-4x);
  padding-bottom: var(--space);
}
