.text-link {
  color: var(--color-link);
  cursor: pointer;
}

.hidden {
  display: none;
}

.mobileOnly,
.tabletWithDown,
.tabletWithUp,
.tabletOnly,
.laptopWithDown,
.laptopWithUp,
.laptopOnly,
.desktopOnly {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobileOnly,
  .tabletWithDown,
  .laptopWithDown {
    display: initial;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tabletOnly,
  .tabletWithDown,
  .laptopWithDown,
  .tabletWithUp {
    display: initial;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .laptopOnly,
  .laptopWithDown,
  .tabletWithUp,
  .laptopWithUp {
    display: initial;
  }
}

@media screen and (min-width: 1041px) {
  .desktopOnly,
  .tabletWithUp,
  .laptopWithUp {
    display: initial;
  }
}
