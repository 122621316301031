.root {
  display: grid;
  flex-wrap: wrap;
}

.columns {
  width: 100%;
}

.spacing--1 {
  grid-column-gap: var(--space-1);
  grid-row-gap: calc(var(--space-1) * 2);
}
.spacing--2 {
  grid-column-gap: var(--space-2);
  grid-row-gap: calc(var(--space-2) * 2);
}
.spacing--base {
  grid-column-gap: var(--space);
  grid-row-gap: calc(var(--space) * 2);
}
.spacing--2x {
  grid-column-gap: var(--space-2x);
  grid-row-gap: calc(var(--space-2x) * 2);
}
.spacing--3x {
  grid-column-gap: var(--space-3x);
  grid-row-gap: calc(var(--space-3x) * 2);
}
.spacing--4x {
  grid-column-gap: var(--space-4x);
  grid-row-gap: calc(var(--space-4x) * 2);
}
.spacing--5x {
  grid-column-gap: var(--space-5x);
  grid-row-gap: calc(var(--space-5x) * 2);
}
.spacing--6x {
  grid-column-gap: var(--space-6x);
  grid-row-gap: calc(var(--space-6x) * 2);
}
.spacing--8x {
  grid-column-gap: var(--space-8x);
  grid-row-gap: calc(var(--space-8x) * 2);
}
.spacing--10x {
  grid-column-gap: var(--space-10x);
  grid-row-gap: calc(var(--space-10x) * 2);
}
.spacing--12x {
  grid-column-gap: var(--space-12x);
  grid-row-gap: calc(var(--space-12x) * 2);
}
.spacing--14x {
  grid-column-gap: var(--space-14x);
  grid-row-gap: calc(var(--space-14x) * 2);
}
.spacing--16x {
  grid-column-gap: var(--space-16x);
  grid-row-gap: calc(var(--space-16x) * 2);
}
.spacing--20x {
  grid-column-gap: var(--space-20x);
  grid-row-gap: calc(var(--space-20x) * 2);
}
.spacing--30x {
  grid-column-gap: var(--space-30x);
  grid-row-gap: calc(var(--space-30x) * 2);
}

.item {
  flex-shrink: 0;
}

/* spacing--row **/
.spacing--row--1 {
  grid-row-gap: var(--space-1);
}
.spacing--row--2 {
  grid-row-gap: var(--space-2);
}
.spacing--row--base {
  grid-row-gap: var(--space);
}
.spacing--row--2x {
  grid-row-gap: var(--space-2x);
}
.spacing--row--3x {
  grid-row-gap: var(--space-3x);
}
.spacing--row--4x {
  grid-row-gap: var(--space-4x);
}
.spacing--row--5x {
  grid-row-gap: var(--space-5x);
}
.spacing--row--6x {
  grid-row-gap: var(--space-6x);
}
.spacing--row--8x {
  grid-row-gap: var(--space-8x);
}
.spacing--row--10x {
  grid-row-gap: var(--space-10x);
}
.spacing--row--12x {
  grid-row-gap: var(--space-12x);
}
.spacing--row--14x {
  grid-row-gap: var(--space-14x);
}
.spacing--row--16x {
  grid-row-gap: var(--space-16x);
}
.spacing--row--20x {
  grid-row-gap: var(--space-20x);
}
.spacing--row--30x {
  grid-row-gap: var(--space-30x);
}

/* spacing--column */
.spacing--column--1 {
  grid-column-gap: var(--space-1);
}
.spacing--column--2 {
  grid-column-gap: var(--space-2);
}
.spacing--column--base {
  grid-column-gap: var(--space);
}
.spacing--column--2x {
  grid-column-gap: var(--space-2x);
}
.spacing--column--3x {
  grid-column-gap: var(--space-3x);
}
.spacing--column--4x {
  grid-column-gap: var(--space-4x);
}
.spacing--column--5x {
  grid-column-gap: var(--space-5x);
}
.spacing--column--6x {
  grid-column-gap: var(--space-6x);
}
.spacing--column--8x {
  grid-column-gap: var(--space-8x);
}
.spacing--column--10x {
  grid-column-gap: var(--space-10x);
}
.spacing--column--12x {
  grid-column-gap: var(--space-12x);
}
.spacing--column--14x {
  grid-column-gap: var(--space-14x);
}
.spacing--column--16x {
  grid-column-gap: var(--space-16x);
}
.spacing--column--20x {
  grid-column-gap: var(--space-20x);
}
.spacing--column--30x {
  grid-column-gap: var(--space-30x);
}

.height--auto {
  height: auto;
}
.height--full {
  height: 100%;
}
.height--fitContent {
  height: fit-content;
}
