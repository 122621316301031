.root {
  display: flex;
  flex-direction: column-reverse;
  color: inherit;
  text-decoration: none;
}

.figure {
  position: relative;
  height: 0;
  padding-top: 60.975%;
  border-radius: var(--rounded);
  overflow: hidden;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: auto;
  vertical-align: middle;
  backface-visibility: hidden;
}

.variant--disabled .img {
  filter: brightness(0.5);
}

.tag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  margin-top: var(--space-3x);
}

.variant--disabled .title {
  opacity: 0.5;
}

.titleIcon {
  flex-shrink: 0;
}

@media screen and (max-width: 1024px) {
  .title {
    margin-top: var(--space-2x);
  }
}
