.note {
  display: flex;
  font-size: var(--fontSize-sm);
}

.text {
  margin-right: var(--space);
}

.note i {
  margin-top: -4px;
}

.content {
  width: 200px;
  white-space: normal;
}

.link {
  color: var(--color-link);
  cursor: pointer;
  text-decoration: underline;
}
