/* Figma https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=3505%3A7580 */

.root {
  background: var(--color-white);
  border: 2px solid var(--color-disabled);
  border-radius: var(--rounded-xs);
  padding: 0 var(--space-3x);
  height: var(--space-12x);
  color: var(--color-black);
  font-size: var(--fontSize-base);
  width: 100%;
}

.root:focus-visible {
  outline: 2px solid var(--color-focus);
}

.root:hover {
  outline: none;
  border: 2px solid var(--color-blue-dark);
}

.disabled {
  background: var(--color-disabled-light);
  color: var(--color-gray-60);
  border-color: var(--color-disabled-light);
  opacity: 1;
}

.disabled:hover {
  border-color: var(--color-disabled-light);
  cursor: not-allowed;
}

.error {
  border-color: var(--color-danger);
}

.noappearance {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.noappearance::-webkit-outer-spin-button,
.noappearance::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
