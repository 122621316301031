.root {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.direction--row-reverse {
  flex-direction: row-reverse;
}

.direction--column-reverse {
  flex-direction: column-reverse;
}

.direction--row {
  flex-direction: row;
}

.direction--column {
  flex-direction: column;
}

.direction--row > .item:not(:first-child) {
  margin-left: var(--space-16x);
}

.direction--row-reverse > .item:not(:last-child) {
  margin-left: var(--space-16x);
}

.direction--column > .item:not(:first-child) {
  margin-top: var(--space-16x);
}

.direction--column-reverse > .item:not(:last-child) {
  margin-top: var(--space-16x);
}

.main {
  width: 100%;
  flex: 1;
}

.sidebar {
  width: 100%;
  place-self: stretch;
}
