.searchButton {
  background-color: var(--bg-white);
  border: solid var(--border-gray) 2px;
  padding: 6px 4px 6px 14px;
  border-radius: 999px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchButton:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 -2px 4px rgba(0, 0, 0, 0.03);
}

.searchButton:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 0 -2px 4px rgba(0, 0, 0, 0.03);
}

.searchButtonLabel {
  color: var(--color-default);
  font-size: var(--fontSize-base);
  font-weight: bold;
  line-height: var(--lineHeight-lg);  
  margin-right: var(--space-4x);
}

.searchIcon {
  background-color: var(--bg-primary);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
