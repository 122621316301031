/* Figma  https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=4477%3A16923*/

.root {
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 40px;
}

.radio {
  appearance: none;
  height: var(--space-5x);
  width: var(--space-5x);
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  margin-right: var(--space-2x);
  cursor: pointer;
  border-color: var(--color-gray-30);
  background-color: var(--color-white);
  margin-right: var(--space-4x);
}

.radio:after {
  opacity: 0;
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  background-color: var(--color-primary);
  height: 10px;
  width: 10px;
  left: 5px;
}

.radio:checked {
  border-color: var(--color-primary);
}

.radio:checked:after {
  opacity: 1;
}

.root * {
  display: flex;
  align-items: center;
}
