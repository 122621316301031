@font-face {
  font-family: Emoji;
  src: local("Apple Color Emoji"), local("Segoe UI Emoji"),
    local("Noto Color Emoji");
  unicode-range: U+2708, U+1F3D6, U+26A0, U+2603;
}

body {
  font-family:
    Emoji,
    -apple-system,
    BlinkMacSystemFont,
    "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-all;
}
body.disabled-scroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
}
