.root {
  position: relative;
  height: 364px;
  border-bottom-left-radius: 48px;
  border-bottom-right-radius: 48px;
  overflow: hidden;
}
.pcWrapper {
  height: 364px;
}

.pcFlex {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.pcLeft {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h1Title {
  margin: 0;
}

.pcTitle {
  margin-top: var(--space-18x);
}

.pcAction {
  margin-top: var(--space-10x);
}

.pcRight {
  margin-top: var(--space-12x);
  height: 270px;
}

.spWrapper {
  display: none;
}

@media screen and (max-width: 1024px) {
  .root {
    height: 320px;
    border-radius: 0;
  }
}
@media screen and (max-width: 767px) {
  .root {
    height: 260px;
  }

  .pcWrapper {
    display: none;
  }

  .spWrapper {
    display: block;
    position: relative;
    height: 260px;
    padding: 0 var(--space-5x);
  }

  .spFlex {
    display: flex;
    justify-content: center;
    height: 178px;
    width: 100%;
  }

  .spLeft {
    position: relative;
    max-width: 300px;
    width: 70%;
  }

  .spRight {
    position: relative;
    width: 50%;
    max-width: 180px;
    height: 200px;
    margin-right: -15px;
    margin-left: -10px;
  }

  .download {
    width: 125px;
  }
}
