@media screen and (max-width: 725px) {
  .carousel {
    margin-inline: calc(var(--space-4x) * -1);
  }
  .carousel li:first-child {
    padding-left: var(--space-4x);
  }
  .carousel li:last-child {
    padding-right: var(--space-4x);
  }
}
