.input {
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 2px solid var(--color-disabled);
  border-radius: var(--rounded-xs);
  padding: 0 var(--space-3x);
  height: var(--space-12x);
  color: var(--color-black);
  font-size: var(--fontSize-base);
  width: 100%;
}

.input.error {
  border-color: var(--color-danger);
}
