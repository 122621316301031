.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.72);
  z-index: var(--z-index-modal);
  overflow: hidden;
}

.init {
  background-color: rgba(0, 0, 0, 0);
}

.opened {
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.2s ease-in;
}
