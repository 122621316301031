.root {
  max-width: 100%;
  display: flex;
}
.root > :first-child {
  border-radius: 32px 0 0 32px;
  margin-left: 0;
  padding: 0 var(--space-3x) 0 var(--space-4x);
  flex: 0 0 auto;
}
.root > :last-child {
  border-radius: 0 32px 32px 0;
}
.checked.body {
  background-color: var(--color-green-light);
  border-color: var(--color-primary);

  /* 横要素の枠線に被るので、チェックが入っている時は z-index: 2; を追加 */
  z-index: 2;
}
.checked.body:not(.checked):hover {
  opacity: 0.8;
}
.body {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-gray-dark);
  justify-content: center;
  background-color: var(--color-white);
  transition: opacity var(--duration);
  font-weight: bold;
  max-width: 100%;

  /* 枠線が被るので、margin-left: -1px; を追加 */
  margin-left: -1px;
}
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.size--sm {
  font-size: var(--fontSize-sm);
  padding: var(--space-2x) var(--space-6x);
  height: 32px;
}
.size--md {
  font-size: var(--fontSize-sm);
  padding: var(--space-2x) var(--space-6x);
  height: 36px;
}
.size--lg {
  font-size: var(--fontSize-base);
  padding: var(--space-2x) var(--space-6x);
  height: 40px;
}

.label + .icon {
  margin-left: var(--space);
}

.icon + .label {
  margin-left: var(--space);
}

@media screen and (max-width: 1024px) {
  .root > :last-child {
    padding-right: var(--space);
  }
  .body {
    flex: 1;
    padding: 0;
    height: 36px;
  }
}
