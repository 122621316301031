.root {
  margin-top: 44px;
}
.image {
  width: 100%;
  border-radius: var(--rounded);
}

@media screen and (max-width: 767px) {
  .root {
    margin-top: 16px;
  }
}
