.root {
  border-bottom: 1px solid var(--border-gray);
}

.tabs {
  display: flex;
}

.tabs.start {
  justify-content: start;
}
.tabs.center {
  justify-content: center;
}

.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  padding-bottom: var(--space);
}

.tab > button {
  border-radius: var(--rounded);
}

.active > button > span {
  position: relative;
}

.active > button > span::after {
  display: block;
  position: absolute;
  top: 22px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: var(--color-primary);
  border-radius: 56px;
}

.inactive > button > span {
  color: var(--color-gray-50);
}

@media screen and (max-width: 767px) {
  .tabs {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .tabs.center,
  .tabs.start {
    justify-content: start;
  }
  .tab > button {
    padding: 0 var(--space-4x);
  }
  .active::after {
    left: 24px;
  }
}
