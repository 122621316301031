.root {
  width: 100%;
  height: auto;
}

.inner {
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* firefox */
}

.inner::-webkit-scrollbar {
  display: none; /* Chrome, Edge, Opera, Safari, all browsers on iOS */
}
