.root {
  display: flex;
}

.centered {
  justify-content: center;
}

.circle {
  width: var(--space-6x);
  height: var(--space-6x);
  background-color: var(--color-disabled);
  border-radius: var(--rounded-full);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle--active {
  background-color: var(--color-black);
}

.circle--mobile {
  width: var(--space-4x);
  height: var(--space-4x);
}

.divider {
  border-top: 3px var(--color-disabled-light) solid;
  width: 40px;
}

.divider--mobile {
  margin-top: var(--space-2x);
}
