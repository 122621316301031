.menuWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100dvh);
  background: var(--color-white);
}

.menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-header);
  width: 100%;
  height: calc(100dvh - 40px);
  background: var(--color-white);
  padding: var(--space-10x) var(--space-5x) 0 var(--space-5x);
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.visible {
  animation: feedIn 0.3s;
}

@keyframes feedIn {
  0% {
    top: 100dvh;
  }
  100% {
    top: 0;
  }
}

.menuButton {
  background-color: transparent;
  border: none;
  width: 48px;
  height: 40px;
  cursor: pointer;
}

.openButton {
  padding-bottom: 2px;
}

.loginButtonArea {
  margin: var(--space-2x) 0 var(--space-8x) 0;
}

.menu .menuButton {
  position: absolute;
  top: 8px;
  right: 0;
}

.menuNav {
  margin: var(--space-4x) 0 var(--space-4x) 0;
}

.menuNav > li .menuNavLink {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.menuNav .menuNavLabel {
  font-size: var(--fontSize-base);
  font-weight: bold;
  flex: 1;
}

.menuNav span.menuNavLabel {
  margin-left: var(--space-4x);
}

h3.menuNavLabel {
  margin-top: var(--space-8x);
}

.menuNav > :first-child h3.menuNavLabel {
  margin-top: var(--space-3x);
}

.menuNavButton {
  border: none;
  background: transparent;
  width: 100%;
  text-align: left;
}

.footerNav {
  margin-top: var(--space-3x);
}
.footerNav > li + li {
  margin-top: var(--space-2x);
}

.footerNavLabel {
  font-size: var(--fontSize-sm);
  color: var(--color-black);
}

@media screen and (max-width: 1024px) {
  .menuButton {
    display: inline-block;
  }
}
