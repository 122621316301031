/* Figma  https://www.figma.com/file/MSrcdpLlv8ZjvsIAftpqKg/components?node-id=3505%3A7583 */

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 var(--space-6x);
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  white-space: nowrap;
  outline: none;
  border-radius: var(--rounded-lg);
}

.root :global(.loader:after) {
  background: var(--color-gray-10) !important;
}

.width--full {
  width: 100%;
}

.width--content {
  width: auto;
}

.width--md {
  width: 335px;
}

.size--xxs {
  font-size: var(--fontSize-xs);
  height: var(--space-4x);
  padding: 0 var(--space-2x);
}

.size--xs {
  font-size: var(--fontSize-sm);
  height: var(--space-6x);
  padding: 0 var(--space-4x);
}

.size--sm {
  font-size: var(--fontSize-base);
  height: var(--space-8x);
}

.size--md {
  font-size: var(--fontSize-lg);
  height: var(--space-10x);
}

.size--lg {
  font-size: var(--fontSize-lg);
  height: var(--space-12x);
}

/* Primary */
.primary--solid {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}
.primary--solid .icon svg path {
  fill: var(--color-white);
}
.primary--solid:hover {
  background-color: var(--color-primary-dark);
}

.primary--outline {
  background-color: var(--color-white);
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.primary--outline .icon svg path {
  fill: var(--color-primary);
}
.primary--outline:hover {
  border-color: var(--color-primary-dark);
  color: var(--color-primary-dark);
}

.primary--ghost {
  background-color: var(--color-white);
  border: 0;
  color: var(--color-primary);
  border-radius: 0px;
}
.primary--ghost .icon svg path {
  fill: var(--color-primary);
}
.primary--ghost:hover {
  background-color: var(--color-gray-5);
}

/*  Default */
.default--solid {
  background-color: var(--color-default);
  border-color: var(--color-default);
  color: var(--color-white);
}
.default--solid .icon svg path {
  fill: var(--color-white);
}
.default--solid:hover {
  background-color: var(--color-default-dark);
}

.default--outline {
  background-color: var(--color-white);
  border-color: var(--color-default);
  color: var(--color-default);
}
.default--outline .icon svg path {
  fill: var(--color-default);
}
.default--outline:hover {
  border-color: var(--color-default-dark);
  color: var(--color-default-dark);
}

.default--ghost {
  background-color: var(--color-white);
  border: 0;
  color: var(--color-default);
  border-radius: 0px;
}
.default--ghost .icon svg path {
  fill: var(--color-default);
}
.default--ghost:hover {
  background-color: var(--color-gray-5);
}

/*  Danger */
.danger--solid {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: var(--color-white);
}
.danger--solid .icon svg path {
  fill: var(--color-white);
}
.danger--solid:hover {
  background-color: var(--color-danger-dark);
}

.danger--outline {
  background-color: var(--color-white);
  border-color: var(--color-danger);
  color: var(--color-danger);
}
.danger--outline .icon svg path {
  fill: var(--color-danger);
}
.danger--outline:hover {
  border-color: var(--color-danger-dark);
  color: var(--color-danger-dark);
}

.danger--ghost {
  background-color: var(--color-white);
  border: 0;
  color: var(--color-danger);
  border-radius: 0px;
}
.danger--ghost .icon svg path {
  fill: var(--color-danger);
}
.danger--ghost:hover {
  background-color: var(--color-gray-5);
}
/* Disabled */
.disabled:hover {
  cursor: not-allowed;
}

.disabled--solid {
  background-color: var(--color-disabled-light);
  border-color: var(--color-disabled-light);
  color: var(--color-disabled);
}

.disabled--outline {
  background-color: var(--color-white);
  border-color: var(--color-disabled);
  color: var(--color-disabled);
}

.disabled--ghost {
  background-color: var(--color-white);
  border: 0;
  color: var(--color-disabled);
  border-radius: 0px;
}

.disabled--solid .icon svg path,
.disabled--outline .icon svg path,
.disabled--ghost .icon svg path {
  fill: var(--color-disabled);
}

.icon + .body {
  padding-left: var(--space-2x);
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
